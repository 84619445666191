import {Component, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {HintService} from '../_services/hint.service';

@Component({
  selector: 'app-new-version',
  templateUrl: './new-version.component.html',
  styleUrls: ['./new-version.component.scss']
})
export class NewVersionComponent implements OnDestroy {

  // DESTROY SUBJECT
  onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private hintService: HintService,
  ) {
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  reload(): void {
    this.hintService.acknowledgeHint();
    window.location.reload();
  }

}
