import {Component, OnDestroy} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AuthService} from '../_services/auth.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnDestroy {

  appVersion = environment.appVersion;
  isSignedIn = false;

  onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private authService: AuthService,
  ) {
    this.getSignedInState();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private getSignedInState(): void {
    this.authService.onNewUser()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((user) => {
        this.isSignedIn = !!user?.uid;
      });
  }

  goTo(type: string): void {
    switch (type) {
      case ('tw'):
        window.open('https://twitter.com/intent/follow?screen_name=PitwallLive', '_blank');
        break;
      case ('fb'):
        window.open('https://www.facebook.com/pitwall.live', '_blank');
        break;
      case ('nsta'):
        window.open('https://www.instagram.com/pitwalllive', '_blank');
        break;
    }
  }

}
