import {AuthInterceptor} from './_interceptors/http.interceptors';
import {BgComponent} from './bg/bg.component';
import {CartComponent} from './cart/cart.component';
import {CommonModule} from '@angular/common';
import {CookiesComponent} from './cookies/cookies.component';
import {FooterComponent} from './footer/footer.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HexagonComponent} from './hexagon/hexagon.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {LoadingComponent} from './loading/loading.component';
import {MatModule} from './mat.module';
import {MmComponent} from './mm/mm.component';
import {NgModule} from '@angular/core';
import {PriceComponent} from './price/price.component';
import {RouterModule} from '@angular/router';
import {SwiperPagComponent} from './swiper-pag/swiper-pag.component';
import {VisibilityDirective} from './_directives/visibility.directive';
import {CarouselComponent} from './carousel/carousel.component';
import {SwiperModule} from 'ngx-swiper-wrapper';
import {PageNavComponent} from './page-nav/page-nav.component';
import {AngularFireAuthModule} from '@angular/fire/compat/auth';

@NgModule({
  declarations: [
    BgComponent,
    CarouselComponent,
    CartComponent,
    CookiesComponent,
    FooterComponent,
    HexagonComponent,
    LoadingComponent,
    MmComponent,
    PageNavComponent,
    PriceComponent,
    SwiperPagComponent,
    VisibilityDirective,
  ],
  exports: [
    BgComponent,
    CarouselComponent,
    CartComponent,
    CommonModule,
    CookiesComponent,
    FooterComponent,
    FormsModule,
    HexagonComponent,
    LoadingComponent,
    MatModule,
    MmComponent,
    PageNavComponent,
    PriceComponent,
    ReactiveFormsModule,
    SwiperPagComponent,
    VisibilityDirective,
  ],
  imports: [
    AngularFireAuthModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    MatModule,
    ReactiveFormsModule,
    RouterModule,
    SwiperModule,
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true}
  ],
})
export class SharedModule { }
