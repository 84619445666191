import {AuthService} from './auth.service';
import {CartModel} from '../_models/cart.model';
import {Injectable} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {SsService} from './ss.service';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  private readonly SS_CART = 'cart';
  private cart$ = new ReplaySubject<CartModel>(1);

  constructor(
    private authService: AuthService,
    private ssService: SsService,
  ) {
    const cartSs: CartModel = this.ssService.getItem(this.SS_CART);
    if (cartSs && !cartSs.isPaid) {
      cartSs.fromSs = true;
    }
    this.newCart(cartSs || null, cartSs?.fromSs);
    this.onUser();
  }

  private onUser(): void {
    this.authService.onNewUser()
      .subscribe((user) => {
        if (!user?.uid) {
          // USER SIGNED-OUT: CLEAR CART
          this.newCart();
        }
      });
  }

  newCart(cart: CartModel = null, fromSs: boolean = false): void {
    if (cart?.products.length) {
      cart.fromSs = fromSs;
      this.ssService.setItem(this.SS_CART, cart);
    } else {
      this.ssService.removeItem(this.SS_CART);
    }
    if (cart?.isPaid) {
      cart.paymentIntent = '';
      cart.invoice = '';
    }
    this.cart$.next(cart);
  }

  onNewCart(): Observable<CartModel> {
    return this.cart$.asObservable();
  }

}
