export const WS_ERROR_RS = 4;

declare type WsEventType =
  '' |
  'auth' |
  'c:gto' |
  'c:ply' |
  'c:pse' |
  'd' |
  'd:c' |  // CAR SELECTION
  'C' |  // CAR LIST
  'error' |
  'gcomm' |
  'r:s' | 'r:bs' | 'r:bl' | 'r:io' | 'r:S' |
  's:flag:F:f' | 'e:flag' | 'F' | 'f' |
  's:gap:G:g' | 'e:gap' | 'G' | 'g' |
  's:lb:L:l' | 'e:lb' | 'L' | 'l' |
  's:msg:M:m' | 'e:msg' | 'M' | 'm' |
  's:perim:E:e' | 'e:perim' | 'E' | 'e' |
  's:sectimes:AL:l' | 'e:sectimes' | 'A' |
  's:speed:V:v' | 'e:speed' | 'V' | 'v' |
  's:stint:SL:sl' | 'e:stint' | 'S' | 's' | // also needs flags, but flags are always subscribed too, no need to do it again
  's:time:L:l' | 'e:time' |
  's:tmPos:PWL:pwl' | 'e:tmPos' | 'P' | 'p' |
  's:tmNoGps:L:l' | 'e:tmNoGps' |
  's:wthr:W:w' | 'e:wthr' | 'W' | 'w' |
  'session' |
  'z'
  ;

export class WsEventModel {
  d: any; // DATA
  ets?: number; // ELAPSED TIME SINCE BEGINNING OF EVENT (DB.EVENTS.BEGINDATE)
  t: WsEventType; // TYPE

  constructor(objIn: any) {
    this.d = objIn.d || null;
    this.ets = objIn.ets || 0;
    this.t = objIn.t || '';
  }

}
