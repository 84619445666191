import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {FuncsService} from './funcs.service';
import firebase from 'firebase/compat';
import {filter, map} from 'rxjs/operators';
import WhereFilterOp = firebase.firestore.WhereFilterOp;
import {documentId} from '@angular/fire/firestore';

export const DB_UID_ATTR = 'Uid';
export const DB_DOCID_ATTR = 'DocId';

@Injectable({
  providedIn: 'root'
})
export class DbService {

  constructor(
    private firestore: AngularFirestore,
  ) {
  }

  // ********************************************************************************************************
  // LOAD DATA
  // ********************************************************************************************************

  listenToDoc(collection: string, doc: string): Observable<any> {
    console.log('listening to document changes for ' + collection + '/' + doc);
    return this.firestore.collection(collection).doc<any>(doc).snapshotChanges()
      .pipe(
        filter((d) => !d.payload.metadata.hasPendingWrites && !d.payload.metadata.fromCache),
        map((d) => {
          // console.log(moment().toISOString() + ': db listener update for ' + collection + ' ' + doc, d, 'data: ', d.payload.data());
          return d.payload.data();
        })
      );
  }

  listenToCollection(collection: string, deltaOnly: boolean): Observable<any> {
    console.log('listening to collection changes for ' + collection);
    return this.firestore.collection(collection).snapshotChanges()
      .pipe(
        map((coll) => {
          if (deltaOnly) {
            // console.log(moment().toISOString() +  ': db listener delta update for ' + collection, coll.filter((c) => c.type === 'modified'));
            return coll
              .filter((c) => c.type === 'modified')
              .map((c) => c.payload.doc.data());
          }
          // console.log(moment().toISOString() +  ': db listener update for ' + collection, coll);
          return coll.map((c) => c.payload.doc.data());
        })
      );
  }

  listenToCollectionQuery(collection: string, query: { attr: string, op: WhereFilterOp, val: string }): Observable<any> {
    console.log('listening to collection query changes for ' + collection);
    return this.firestore.collection(
      collection,
      ref => ref.where(query.attr, query.op, query.val)
    ).snapshotChanges()
      .pipe(
        map((coll) => {
          // console.log(moment().toISOString() + ': db listener update for ' + collection, coll);
          return coll.map((c) => {
            return {[DB_DOCID_ATTR]: c.payload.doc.id, ...c.payload.doc.data() as any};
          });
        })
      );
  }

  getDoc(collection: string, doc: string): Observable<any> {
    console.log('getting document ' + collection + '/' + doc);
    return this.firestore.collection(collection).doc<any>(doc).get()
      .pipe(
        map((d) => {
          // console.log('db get for ' + collection, coll);
          return d.data();
        })
      );
  }

  getCollection(collection: string): Observable<any[]> {
    console.log('getting collection ' + collection);
    return this.firestore.collection(collection).get()
      .pipe(
        map((coll) => {
          // console.log('db get for ' + collection, coll);
          return coll.docs.map((d) => d.data());
        })
      );
  }

  getCollectionQuery(collection: string, query: { attr: string, op: WhereFilterOp, val: string | string[] }): Observable<any[]> {
    console.log('getting collection query for ' + collection);
    return this.firestore.collection(
      collection,
      ref => ref.where(
        query.attr === DB_DOCID_ATTR ? documentId() : query.attr,
        query.op,
        query.val
      )
    ).get()
      .pipe(
        map((coll) => {
          // console.log('db get for ' + collection, coll);
          return coll.docs.map((c) => {
            return {[DB_DOCID_ATTR]: c.id, ...c.data() as any};
          });
        })
      );
  }

  // ********************************************************************************************************
  // CHANGE DATA
  // ********************************************************************************************************

  set(collection: string, doc: string, data: any, setUid = ''): Promise<any> {
    if (setUid) {
      data[DB_UID_ATTR] = setUid;
    }
    if (doc) {
      return this.firestore.collection(collection).doc(doc).set(
        data,
        {merge: true}
      );
    } else {
      return this.firestore.collection(collection).add(
        data
      );
    }
  }

  batch(collection: string, data: any[], setUid = ''): Promise<any> {
    const batch = this.firestore.firestore.batch();
    for (const d of data) {
      if (setUid) {
        d[DB_UID_ATTR] = setUid;
      }
      const newId = this.firestore.createId();
      const docRef = this.firestore.collection(collection).doc(newId).ref;
      batch.set(docRef, FuncsService.copy(d));
    }
    return batch.commit();
  }

  delete(collection: string, doc: string): Promise<any> {
    return this.firestore.collection(collection).doc(doc).delete();
  }

  // ********************************************************************************************************
  // BROADCAST DATA
  // ********************************************************************************************************

}
