import {BehaviorSubject, Observable} from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private loading = [];
  private loading$ = new BehaviorSubject<string[]>([]);

  // ********************************************************************************************************
  // SAVE DATA
  // ********************************************************************************************************

  start(newLoader: string): void {
    this.loading.push(newLoader);
    this.newLoading();
  }

  stop(newLoader: string): void {
    this.loading = this.loading.filter((l) => l !== newLoader);
    this.newLoading();
  }

  // ********************************************************************************************************
  // BROADCAST DATA
  // ********************************************************************************************************

  newLoading() {
    this.loading$.next(this.loading);
  }

  onNewLoading(): Observable<string[]> {
    return this.loading$.asObservable();
  }

  getLoading(): string[] {
    return JSON.parse(JSON.stringify(this.loading$.getValue()));
  }

}
