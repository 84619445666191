import {Component, OnDestroy} from '@angular/core';
import {HintModel} from '../_models/hint.model';
import {HintService} from '../_services/hint.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-hint',
  templateUrl: './hint.component.html',
  styleUrls: ['./hint.component.scss']
})

export class HintComponent implements OnDestroy {

  hint: HintModel;
  hintActive = false;

  onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private hintService: HintService,
  ) {
    this.getHint();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  // ********************************************************************************************************
  // LOAD DATA
  // ********************************************************************************************************

  private getHint(): void {
    this.hintService.onNewHint()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (hint) => {
          this.hintActive = !!hint;
          this.hint = hint;
        }
      );
  }

  // ********************************************************************************************************
  // UI
  // ********************************************************************************************************

  closeHint(): void {
    this.hintService.newHint(null);
  }

}
