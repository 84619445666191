import {fromEvent, timer} from 'rxjs';
import {HintService} from './hint.service';
import {Injectable} from '@angular/core';
import {Platform} from '@angular/cdk/platform';
import {take} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PwaInstallService {

  private promptEvent: any;

  constructor(
    private hintService: HintService,
    private platform: Platform,
  ) { }

  initPwaPrompt() {
    if (!this.platform.IOS) {
      fromEvent(window, 'beforeinstallprompt')
        .subscribe(
          (e) => {
            e.preventDefault();
            this.promptEvent = e;
            this.openHint();
          }
        );
    } else {
      const isInStandaloneMode = ('standalone' in window.navigator) && (window.navigator['standalone']);
      if (!isInStandaloneMode) {
        this.openHint();
      }
    }
  }

  private openHint() {
    if (this.hintService.isHintAcknowledged('install')) {
      return;
    }
    timer(3000)
      .pipe(take(1))
      .subscribe(() => {
        this.hintService.newHint('install');
      });
  }

  doInstall(): void {
    this.hintService.acknowledgeHint();
    this.promptEvent.prompt();
  }

  ignoreInstall(): void {
    this.hintService.acknowledgeHint();
  }

}
