import {AuthService} from './auth.service';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {environment} from '../../../environments/environment';
import {ErrorService} from './error.service';
import {Injectable, OnDestroy} from '@angular/core';
import {LsService} from './ls.service';
import {Router} from '@angular/router';
import {SbService} from './sb.service';
import {NO_ACTIVE_SERVER, ServerModel, ServerPitmondModel} from '../_models/server.model';
import {takeUntil} from 'rxjs/operators';
import {WsService} from './ws.service';
import {ActiveRouteService} from './active-route.service';
import {DbService} from './db.service';

@Injectable({
  providedIn: 'root'
})
export class ServersService implements OnDestroy {

  private serversFromDb: ServerModel[] = [];
  private servers$ = new BehaviorSubject<ServerPitmondModel[]>([]);

  private serverActive$ = new BehaviorSubject<ServerModel>(null);
  private serverComm$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  readonly LS_SERVER_ACTIVE = 'serverActive';

  private rawDirectLink = '';

  private isListening = false;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private activeRouteService: ActiveRouteService,
    private authService: AuthService,
    private dbService: DbService,
    private errorService: ErrorService,
    private lsService: LsService,
    private router: Router,
    private sbService: SbService,
    private wsService: WsService,
  ) {
    this.onActiveRoute();
    this.getServersFromDb();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  // ********************************************************************************************************
  // LOAD DATA
  // ********************************************************************************************************

  private onActiveRoute(): void {
    this.activeRouteService.onNewActiveRoute()
      .subscribe((route) => {
        if (route.url.includes('timing')) {
          if (!route.params.server || route.params.server === NO_ACTIVE_SERVER) {
            // check if there is an active server stored in ls
            const serverActive: ServerModel = this.lsService.getItem(this.LS_SERVER_ACTIVE);
            // check against no_active server: ls could contain that as a server if user did not select a correct server from the list and then navigates
            if (serverActive?.name && serverActive.name !== NO_ACTIVE_SERVER) {
              this.router.navigate(['/timing/' + serverActive.directLink]);
              return;
            }
          }
          this.setDirectLinkServer(route.params.server);
        }
      });
  }

  private getServersFromDb(): void {
    this.dbService.getCollection(environment.db.streams)
      .subscribe({
          next: (servers) => {
            if (servers?.length) {
              this.serversFromDb = servers.map((c) => new ServerModel(c));
              // SERVERS RECEIVED, NOW GET STATUS
              this.listenToStatusUpdates();
              // IF DIRECT LINK RECEIVED BEFORE SERVERS, SET DIRECT LINK NOW
              if (this.rawDirectLink) {
                this.setDirectLinkServer(this.rawDirectLink);
              }
              this.checkIfActiveServerComm();
            } else {
              this.sbService.message('Something went wrong loading the list of servers. Could you refresh to try again?');
            }
          },
          error: (err) => {
            this.sbService.message(err);
          }
        }
      );
  }

  private listenToStatusUpdates(): void {
    if (!this.isListening) {
      this.isListening = true;
      this.dbService.listenToCollection(environment.db.pitmond, false)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe({
            next: (stats) => {
              if (stats.length) {
                this.setServersStatus(stats);
              }
            }
          }
        );
    }
  }

  // ********************************************************************************************************
  // PROCESS DATA
  // ********************************************************************************************************

  private setServersStatus(stats: any[]): void {
    const servers: ServerPitmondModel[] = [];
    for (const curServer of this.serversFromDb) {
      const tmpRawStatus = stats.find((s) => s?.Id === curServer.id);
      servers.push(new ServerPitmondModel(
        curServer,
        tmpRawStatus?.Pwb || {},
        tmpRawStatus?.Pwr || {}
      ));
    }
    if (servers.length) {
      servers.sort(
        (a, b) => a.name.toLowerCase() + a.db.category.toLowerCase() < b.name.toLowerCase() + b.db.category.toLowerCase() ? -1 : 1
      );
    }
    this.newServers(servers);
  }

  setDirectLinkServer(name: string): void {
    this.rawDirectLink = name;
    if (this.serversFromDb.length && this.rawDirectLink) {
      const tmpServer = this.serversFromDb.find((s) => s.directLink.toLowerCase() === this.rawDirectLink.toLowerCase()) || new ServerModel({Name: NO_ACTIVE_SERVER});
      if (tmpServer.name === NO_ACTIVE_SERVER && name !== NO_ACTIVE_SERVER) {
        // server not found while it was not "no_active-server" : means the direct link is wrong
        this.sbService.message('The servername you specified in the url is not correct. Try choosing one from the list instead.');
      }
      this.rawDirectLink = '';
      this.newActiveServer(tmpServer);
    }
  }

  private checkIfActiveServerComm(): void {
    const activeServer = this.getActiveServer();
    if (activeServer) {
      const fromDb = this.serversFromDb.find((s) => s.id === activeServer.id);
      if (fromDb && fromDb.comm !== this.getServerComm()) {
        this.newServerComm(fromDb.comm);
      }
    }
  }

  // ********************************************************************************************************
  // BROADCAST DATA
  // ********************************************************************************************************

  private newServers(newServers: ServerPitmondModel[]) {
    this.servers$.next(newServers);
  }

  onNewServers(): Observable<ServerPitmondModel[]> {
    return this.servers$.asObservable();
  }

  getServers(): ServerPitmondModel[] {
    return this.servers$.getValue();
  }

  newActiveServer(newServer: ServerModel): void {
    // console.log('new active server');
    this.lsService.setItem(this.LS_SERVER_ACTIVE, newServer);
    this.serverActive$.next(newServer);
    if (newServer?.port) {
      newServer.url = newServer.url || environment.urlWs;
      this.wsService.newWsAddress(newServer.url + ':' + newServer.port);
    }
    this.checkIfActiveServerComm();
  }

  onNewActiveServer(): Observable<ServerModel> {
    return this.serverActive$.asObservable();
  }

  getActiveServer(): ServerModel {
    return this.serverActive$.getValue();
  }

  getServerById(id: string): ServerPitmondModel {
    const servers = this.servers$.getValue();
    if (servers?.length) {
      return servers.find((s) => s.db.id === id);
    }
    return null;
  }

  newServerComm(gcomm: string): void {
    this.serverComm$.next(gcomm);
  }

  onNewServerComm(): Observable<string> {
    return this.serverComm$.asObservable();
  }

  getServerComm(): string {
    return this.serverComm$.getValue();
  }

}
