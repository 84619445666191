<div *ngIf="imgIdx > -1"
     class="carousel flex col"
     [class.boxed]="isBoxed">

  <div *ngIf="!isBoxed"
       class="carousel__close"
       (click)="onClose()">
    <svg viewBox="0 0 24 24">
      <use xlink:href="assets/icons/close_right.svg#svg1"></use>
    </svg>
  </div>

  <div class="carousel__swiper swiper-container"
       [swiper]="swiperConfig"
       [(index)]="imgIdx">
    <div class="swiper-wrapper">

      <div *ngFor="let img of imgList"
           class="carousel__swiper__slide swiper-slide">
        <div class="carousel__swiper__slide__placeholder"
             [style.background-image]="'url(' + img.url + ')'"></div>
      </div>

    </div>
  </div>

  <app-swiper-pag *ngIf="labelList?.length > 1"
                  [swiperIdx]="imgIdx"
                  [list]="labelList"
                  [cssClass]="cssClass"
                  [strokeWidth]="34"
                  (onPagination)="onPagination($event)">
  </app-swiper-pag>

</div>
