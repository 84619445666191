import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FuncsService {

  static copy<T>(objIn: T): any {
    return objIn && typeof objIn === 'object' ? JSON.parse(JSON.stringify(objIn)) : null;
  }

  static checkJSON(itemToCheck: string): boolean {
    let item = {};
    try {
      item = JSON.parse(itemToCheck);
    } catch (e) {
      return false;
    }
    return typeof item === 'object' && item !== null;
  }

  // DISTINCT SORTED LIST WITHOUT NULL/UNDEFINED VALUES
  static distinct(listIn: string[]): string[] {
    return listIn
      .filter((value, index, self) => value && self.indexOf(value) === index)
      .sort((a, b) => a < b ? -1 : 1);
  }

}
