import {Component, Input, OnDestroy} from '@angular/core';
import {VpService} from '../_services/vp.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-page-nav',
  templateUrl: './page-nav.component.html',
  styleUrls: ['./page-nav.component.scss']
})
export class PageNavComponent implements OnDestroy {

  @Input() navIdx = 0;
  @Input() navItems: string[] = [];

  navWidth = 164;
  navFixed = false;

  onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private vpService: VpService,
  ) {
    this.onVp();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private onVp(): void {
    this.vpService.onNewVp()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (vp) => {
          this.navFixed = vp.winWidth > 1400 + 2 * this.navWidth;
        }
      );
  }

  goTo(idx: number): void {
    this.navIdx = idx;
    this.vpService.scrollToFragment(this.navItems[idx]);
  }

}
