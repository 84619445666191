<div class="page-nav"
     [style.width.px]="navWidth"
     [class.fixed]="navFixed">
  <div *ngFor="let item of navItems; let itemIdx = index;"
       class="page-nav__item flex end"
       [class.accent]="navIdx === itemIdx"
       (click)="goTo(itemIdx)">
    {{item}}
    <app-hexagon [hexSize]="14"
                 [hexClass]="navIdx === itemIdx ? 'accent' : 'color'"
                 [hexStrokeWidth]="54"
                 [hexWithFill]="navIdx === itemIdx"
                 style="margin-left: 7px;">
    </app-hexagon>
  </div>
</div>
