import {Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {CarouselImgModel} from '../_models/carousel.model';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit, OnDestroy {

  @Input() cssClass = 'accent';
  @Input() imgIdx: number;
  @Input() imgList: CarouselImgModel[];
  @Input() isBoxed = false;

  @Output() closed = new EventEmitter<void>();

  swiperConfig = {
    grabCursor: true,
    spaceBetween: 44,
  };

  labelList: string[] = [];

  private onDestroy$: Subject<void> = new Subject<void>();

  @HostListener('document:keydown.escape', ['$event']) onKeyDown(e: KeyboardEvent): void {
    if (e.repeat) {
      return;
    }
    this.closed.emit();
  }

  ngOnInit() {
    this.labelList = this.imgList.map((i) => i.label);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  // ********************************************************************************************************
  // LOAD DATA
  // ********************************************************************************************************

  // ********************************************************************************************************
  // UI
  // ********************************************************************************************************

  // EVENT LISTENERS

  onClose(): void {
    this.closed.emit();
  }

  onPagination(idx: number): void {
    this.imgIdx = idx;
  }

}
