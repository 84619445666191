import * as moment from 'moment';
import {ProductLevels, ProductLevelsDesc} from './product.model';

export class UserModel {
  db: UserUiModel;
  displayName: string;
  email: string;
  emailVerified: boolean; // FOR UI, USERS WHO USED EMAIL TO SIGN-UP SHOULD VERIFY THAT EMAIL FIRST
  photoUrl: string;
  providerData: any[];
  uid: string;
  withEmail: boolean; // FOR UI, SOME ACTIONS (CHANGE PASSWORD) OR ONLY AVAILABLE TO USERS WHO USED EMAIL TO SIGN-UP
  ws: UserWsModel;

  constructor(objIn: any = {}) {
    this.db = new UserUiModel(objIn);
    this.displayName = objIn.displayName || objIn.email || '';
    this.email = objIn.email || '';
    this.emailVerified = objIn.emailVerified || false;
    this.photoUrl = objIn.photoURL || '';
    this.providerData = objIn.providerData || [];
    this.uid = objIn?.uid || '';
    this.withEmail = objIn.providerData?.some((p) => p.providerId === 'password');
    this.ws = new UserWsModel(); // will be filled up later by ws auth response
  }

  getLevelName(type: 'ws' | 'db'): string {
    const level = type === 'ws' ? this.ws.level : this.db.level;
    return ProductLevelsDesc[level] || '';
  }

}

// ********************************************************************************************************
// UI VERSION OF DB CLASS
// ********************************************************************************************************

export class UserUiModel {
  level: number;
  paywallId: string;
  paywallPending: UserUiPaywallPendingModel;
  profiles: UserUiProfilesModel[];
  settings: UserUiSettingsModel;
  ts: string;

  constructor(objIn: UserDbModel) {
    this.level = objIn.Ts ? UserUiModel.setPrimProfile(objIn?.Profiles || []) : 0;
    this.paywallId = objIn?.PaywallId || '';
    this.paywallPending = new UserUiPaywallPendingModel(objIn?.PaywallPending);
    this.profiles = UserUiModel.setProfiles(objIn?.Profiles || []);
    this.settings = new UserUiSettingsModel(objIn?.Settings);
    this.ts = objIn?.Ts || '';
  }

  private static setPrimProfile(profiles: UserDbProfilesModel[]): number {
    let primProf = 0;
    for (const profile of profiles) {
      // 2 DAY GRACE PERIOD FOR USER
      if (moment(profile.EndDate).add(2, 'days').isAfter(moment())) {
        primProf = ProductLevels[profile.Product];
        break;
      }
    }
    return primProf || 1; // SET BY DEFAULT TO 1 CUZ IF THIS FUNCTION IS CALLED, THE USER HAS A DB ENTRY => USER IS FREE, NOT ANONYMOUS
  }

  private static setProfiles(profiles: UserDbProfilesModel[] = []): UserUiProfilesModel[] {
    const dbProfiles: UserUiProfilesModel[] = [];
    for (const curProf of profiles) {
      dbProfiles.push(new UserUiProfilesModel(curProf));
    }
    return dbProfiles;
  }

}

export class UserUiProfilesModel {
  cancelAtPeriodEnd: boolean;
  endDate: string;
  product: string;
  subscriptionId: string;

  constructor(objIn: UserDbProfilesModel) {
    this.cancelAtPeriodEnd = objIn.hasOwnProperty('CancelAtPeriodEnd') ? objIn.CancelAtPeriodEnd : false;
    this.endDate = objIn.EndDate || '';
    this.product = objIn.Product || '';
    this.subscriptionId = objIn.SubscriptionId || '';
  }
}

export class UserUiPaywallPendingModel {
  date: string;
  product: string;

  constructor(objIn: UserDbPaywallPendingModel) {
    this.date = objIn?.Date || '';
    this.product = objIn?.Product || '';
  }

}

export class UserUiSettingsModel {
  acceptedCookies: boolean;
  hasAcceptedTransfer: boolean;
  hasRepliedToTransfer: boolean;
  lightTheme: boolean;
  sync: boolean;

  constructor(objIn: UserDbSettingsModel) {
    this.acceptedCookies = objIn && objIn.hasOwnProperty('AcceptedCookies') ? objIn.AcceptedCookies : false;
    this.hasAcceptedTransfer = objIn?.HasAcceptedTransfer || false;
    this.hasRepliedToTransfer = objIn?.HasRepliedToTransfer || false;
    this.lightTheme = objIn && objIn.hasOwnProperty('LightTheme') ? objIn.LightTheme : false;
    this.sync = objIn && objIn.hasOwnProperty('Sync') ? objIn.Sync : false;
  }

}

export class UserWsModel {
  uid: string;
  level: number;

  constructor() {
    this.level = null;
    this.uid = '';
  }

}

// ********************************************************************************************************
// DB CLASS
// ********************************************************************************************************

export class UserDbModel {
  PaywallId?: string;
  PaywallPending?: UserDbPaywallPendingModel;
  Profiles?: UserDbProfilesModel[];
  Settings: UserDbSettingsModel;
  Ts: string;
  Uid: string;

  constructor(uid: string, objIn: UserUiModel) {
    this.PaywallId = objIn?.paywallId || '';
    this.PaywallPending = new UserDbPaywallPendingModel(objIn?.paywallPending);
    this.Profiles = UserDbModel.setDbProfiles(objIn.profiles);
    this.Settings = new UserDbSettingsModel(objIn?.settings);
    this.Uid = uid || '';
  }

  private static setDbProfiles(profiles: UserUiProfilesModel[] = []): UserDbProfilesModel[] {
    const dbProfiles: UserDbProfilesModel[] = [];
    for (const curProf of profiles) {
      dbProfiles.push(new UserDbProfilesModel(curProf));
    }
    return dbProfiles;
  }

}

export class UserDbProfilesModel {
  CancelAtPeriodEnd: boolean;
  EndDate: string;
  Product: string;
  SubscriptionId: string;

  constructor(objIn: UserUiProfilesModel) {
    this.CancelAtPeriodEnd = objIn.hasOwnProperty('cancelAtPeriodEnd') ? objIn.cancelAtPeriodEnd : false;
    this.EndDate = objIn.endDate || '';
    this.Product = objIn.product || '';
    this.SubscriptionId = objIn.subscriptionId || '';
  }

}

export class UserDbPaywallPendingModel {
  Date: string;
  Product: string;

  constructor(objIn: UserUiPaywallPendingModel) {
    this.Date = objIn?.date || '';
    this.Product = objIn?.product || '';
  }

}

export class UserDbSettingsModel {
  AcceptedCookies: boolean;
  HasAcceptedTransfer: boolean;
  HasRepliedToTransfer: boolean;
  LightTheme: boolean;
  Sync: boolean;

  constructor(objIn: UserUiSettingsModel) {
    this.AcceptedCookies = objIn && objIn.hasOwnProperty('acceptedCookies') ? objIn.acceptedCookies : false;
    this.HasAcceptedTransfer = objIn.hasAcceptedTransfer || false;
    this.HasRepliedToTransfer = objIn.hasRepliedToTransfer || false;
    this.LightTheme = objIn && objIn.hasOwnProperty('lightTheme') ? objIn.lightTheme : false;
    this.Sync = objIn && objIn.hasOwnProperty('sync') ? objIn.sync : false;
  }

}
