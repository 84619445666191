import {Injectable, NgZone} from '@angular/core';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SbService {

  private confirm$: BehaviorSubject<string>;
  private readonly sbConfig: MatSnackBarConfig;

  constructor(
    public snackbar: MatSnackBar,
    private zone: NgZone,
  ) {
    this.confirm$ = new BehaviorSubject<string>('');
    this.sbConfig = new MatSnackBarConfig();
    this.sbConfig.duration = 10000;
    this.sbConfig.panelClass = 'mat-sb-multiline';
  }

  message(message: string, action: string = '') {
    this.zone.run(() => {
      this.snackbar.open(message, action, this.sbConfig);
    });
  }

  confirm(message: string, action: string = 'Got it', confirmType: string) {
    this.zone.run(() => {
      const snack = this.snackbar.open(message, action, this.sbConfig);
      snack.onAction()
        .subscribe(
          () => {
            this.newConfirm(confirmType);
          });
    });
  }

  private newConfirm(newConfirm: string) {
    this.confirm$.next(newConfirm);
  }

  onNewConfirm(): Observable<string> {
    return this.confirm$.asObservable();
  }

  close() {
    this.zone.run(() => {
      this.snackbar.dismiss();
    });
  }

}
