import * as moment from 'moment';
import {Component, OnDestroy} from '@angular/core';
import {SbService} from '../../shared/_services/sb.service';
import {Subject} from 'rxjs';
import {ActiveRouteService} from '../../shared/_services/active-route.service';
import {DbService} from '../../shared/_services/db.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnDestroy {

  contactCompany = '';
  contactEmail = '';
  contactName = '';
  contactPhoneNr = '';
  contactQuestion = '';
  contactSubject = '';

  contactSubjects = ['embeddable', 'partner', 'timeservice', 'al kamel', 'its chrono', 'other'];

  onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private activeRouteService: ActiveRouteService,
    private dbService: DbService,
    private sbService: SbService,
  ) {
    const route = this.activeRouteService.getActiveRoute();
    if (route.queryParams && route.queryParams.hasOwnProperty('subject')) {
      this.contactSubject = route.queryParams.subject;
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onSend(): void {
    this.dbService.set(
      environment.db.contact,
      '',
      {
        Company: this.contactCompany,
        Date: moment().format('YYYY-MM-DD'),
        Email: this.contactEmail,
        Name: this.contactName,
        Phone: this.contactPhoneNr,
        Question: this.contactQuestion,
        Subject: this.contactSubject,
      }
    )
      .then(() => {
        this.resetVals();
        this.sbService.message('Question submitted, we will get back to you asap!');
      })
      .catch((err) => {
        this.sbService.message(err);
      });
  }

  private resetVals(): void {
    this.contactCompany = '';
    this.contactEmail = '';
    this.contactName = '';
    this.contactPhoneNr = '';
    this.contactQuestion = '';
    this.contactSubject = '';
  }

}
