import {BehaviorSubject, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {ProductModel, ProductType} from '../_models/product.model';
import {SbService} from './sb.service';
import {DbService} from './db.service';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  private products$ = new BehaviorSubject<ProductModel[]>([]);

  constructor(
    private dbService: DbService,
    private sbService: SbService,
  ) {
    this.getProducts();
  }

  private getProducts(): void {
    this.dbService.getCollection(environment.db.products)
      .subscribe({
          next: (products) => {
            if (products?.length) {
              this.newProducts(products.map((p) => new ProductModel(p)));
            } else {
              this.sbService.message('Something went wrong loading the list of products. Could you refresh to try again?');
            }
          },
          error: (err) => {
            this.sbService.message(err);
          }
        }
      );
  }

  newProducts(products: ProductModel[]): void {
    this.products$.next(products);
  }

  onNewProducts(): Observable<ProductModel[]> {
    return this.products$.asObservable();
  }

  getProductByType(productType: ProductType): ProductModel {
    const emptyProduct = new ProductModel({});
    const prods = this.products$.getValue();
    return prods?.find((p) => p.name === productType) || emptyProduct;
  }

  getProductByPriceId(priceId: string): ProductModel {
    const emptyProduct = new ProductModel({});
    const prods = this.products$.getValue();
    return prods?.find((p) => p.priceId === priceId) || emptyProduct;
  }

}
