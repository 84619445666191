import {Component, ElementRef, OnDestroy, ViewChild} from '@angular/core';
import {map, takeUntil} from 'rxjs/operators';
import {fromEvent, Subject} from 'rxjs';
import {ActiveRouteService} from '../../shared/_services/active-route.service';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {AuthService} from '../../shared/_services/auth.service';
import {UserModel} from '../../shared/_models/user.model';

export enum WindowMessageEnum {
  'getPath' = '1_get_path',
  'setPath' = '2_set_path',
  'getUser' = '3_get_user',
  'setUser' = '4_set_user',
}

export class WindowMessageModel {
  msgType: WindowMessageEnum;
  msgData: any;

  constructor(type: WindowMessageEnum, data: any = '') {
    this.msgType = type;
    this.msgData = data;
  }

}

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnDestroy {

  @ViewChild('iframe_div') iframeDiv: ElementRef;

  blogUrl: SafeResourceUrl;
  blogWantsUser = false;
  user: UserModel;

  // DESTROY SUBJECT
  private onDestroy$: Subject<void> = new Subject<void>();

  private static postMessage(source: Window, msg: WindowMessageModel): void {
    if (source) {
      source.postMessage(msg, '*');
    }
  }

  constructor(
    private activeRouteService: ActiveRouteService,
    private authService: AuthService,
    private sanitizer: DomSanitizer,
  ) {
    this.onUser();
    this.setBlogUrl();
    this.listenToChildMessages();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  // ********************************************************************************************************
  // LOAD DATA
  // ********************************************************************************************************

  private onUser(): void {
    this.authService.onNewUser()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((user) => {
        this.user = user;
        this.sendUserId();
      });
  }

  private listenToChildMessages(): void {
    fromEvent<MessageEvent>(window, 'message')
      .pipe(
        takeUntil(this.onDestroy$),
        map((m) =>
          new WindowMessageModel(m?.data?.msgType || '', m?.data?.msgData || '')
        )
      )
      .subscribe((msg) => {
        if (msg.msgType === WindowMessageEnum.getPath) {
          // console.log('parent received request for path', msg);
          const blogPostId = this.activeRouteService.getActiveRoute()?.params.post_id;
          if (blogPostId) {
            BlogComponent.postMessage(
              this.iframeDiv.nativeElement.contentWindow,
              new WindowMessageModel(WindowMessageEnum.setPath, blogPostId)
            );
          }
        }
        if (msg.msgType === WindowMessageEnum.getUser) {
          this.blogWantsUser = true;
          this.sendUserId();
        }
      });
  }

  private sendUserId(): void {
    if (this.user?.uid && this.blogWantsUser) {
      BlogComponent.postMessage(
        this.iframeDiv.nativeElement.contentWindow,
        new WindowMessageModel(WindowMessageEnum.setUser, this.user.uid)
      );
    }
  }

  // ********************************************************************************************************
  // UI
  // ********************************************************************************************************

  private setBlogUrl(): void {
    if (window.location.host.includes('localhost')) {
      this.blogUrl = this.sanitizer.bypassSecurityTrustResourceUrl('http://localhost:4445/posts?ts=' + performance.now());
    } else {
      this.blogUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://blog.pitwall.live/posts?ts=' + performance.now());
    }
  }

}
