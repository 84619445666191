export declare type ThemeType = 'd00001' | 'l00001' | 'o00001' | 's00001';

export class ThemeModel {
  code: ThemeType;
  name: string;
  properties: any;

  constructor(code: ThemeType, name: string, props: any) {
    this.code = code;
    this.name = name;
    this.properties = props;
  }

  getRgb(color: string, alpha = 1): string {
    const h = Number(this.properties['--' + color + '-h']);
    let s = Number(this.properties['--' + color + '-s'].replace('%', ''));
    let l = Number(this.properties['--' + color + '-l'].replace('%', ''));

    // Must be fractions of 1
    s /= 100;
    l /= 100;

    const c = (1 - Math.abs(2 * l - 1)) * s,
      x = c * (1 - Math.abs((h / 60) % 2 - 1)),
      m = l - c / 2;
    let r = 0,
      g = 0,
      b = 0;

    if (0 <= h && h < 60) {
      r = c;
      g = x;
      b = 0;
    } else if (60 <= h && h < 120) {
      r = x;
      g = c;
      b = 0;
    } else if (120 <= h && h < 180) {
      r = 0;
      g = c;
      b = x;
    } else if (180 <= h && h < 240) {
      r = 0;
      g = x;
      b = c;
    } else if (240 <= h && h < 300) {
      r = x;
      g = 0;
      b = c;
    } else if (300 <= h && h < 360) {
      r = c;
      g = 0;
      b = x;
    }
    r = Math.round((r + m) * 255);
    g = Math.round((g + m) * 255);
    b = Math.round((b + m) * 255);

    return 'rgba(' + r + ',' + g + ',' + b + ',' + alpha + ')';
  }
}

export const Themes: ThemeModel[] = [

  // DARK THEME
  new ThemeModel(
    'd00001',
    'dark',
    {
      '--font': 'Play',
      '--bg-h': 0,
      '--bg-s': '0%',
      '--bg-l': '14%',
      '--bg-op': 1,
      '--bg-accent': '8%',
      '--bg-accent-small': '4%',
      '--bg-darken': '-40%',
      '--bg-op-overlay': 0.6,
      '--bg-op-min': 0.8,
      '--bg-op-max': 0.2,
      '--color-h': 0,
      '--color-s': '0%',
      '--color-l': '87.8%',
      '--color-accent': '10%',
      '--color-op-min': 0.8,
      '--color-op-max': 0.6,
      '--primary-h': 120,
      '--primary-s': '75%',
      '--primary-l': '50%',
      '--primary-op-min': 0.8,
      '--primary-op-max': 0.4,
      '--accent-h': 19,
      '--accent-s': '100%',
      '--accent-l': '52.2%',
      '--accent-op-min': 0.95,
      '--accent-op-max': 0.4,
      '--accent2-h': 199,
      '--accent2-s': '77.4%',
      '--accent2-l': '74%',
      '--accent2-op-min': 0.8,
      '--accent2-op-max': 0.4,
      '--accent3-h': 207,
      '--accent3-s': '98%',
      '--accent3-l': '37%',
      '--accent3-op-min': 0.8,
      '--accent3-op-max': 0.4,
      '--error-h': 0,
      '--error-s': '100%',
      '--error-l': '50%',
      '--error-op-min': 0.6,
      '--error-op-max': 0.4,
      '--disabled-h': 0,
      '--disabled-s': '0%',
      '--disabled-l': '30%',
      '--disabled-op-min': 0.8,
      '--disabled-op-max': 0.4,
    }
  ),

  // LIGHT THEME
  new ThemeModel(
    'l00001',
    'light',
    {
      '--font': 'Play',
      '--bg-h': 0,
      '--bg-s': '0%',
      '--bg-l': '94%',
      '--bg-op': 1,
      '--bg-accent': '-6%',
      '--bg-accent-small': '-2%',
      '--bg-darken': '-40%',
      '--bg-op-overlay': 0.4,
      '--bg-op-min': 0.84,
      '--bg-op-max': 0.2,
      '--color-h': 0,
      '--color-s': '0%',
      '--color-l': '20%',
      '--color-accent': '-10%',
      '--color-op-min': 0.8,
      '--color-op-max': 0.6,
      '--primary-h': 120,
      '--primary-s': '50%',
      '--primary-l': '50%',
      '--primary-op-min': 0.8,
      '--primary-op-max': 0.4,
      '--accent-h': 19,
      '--accent-s': '100%',
      '--accent-l': '52.2%',
      '--accent-op-min': 0.95,
      '--accent-op-max': 0.4,
      '--accent2-h': 199,
      '--accent2-s': '77.4%',
      '--accent2-l': '54%',
      '--accent2-op-min': 0.8,
      '--accent2-op-max': 0.4,
      '--accent3-h': 207,
      '--accent3-s': '98%',
      '--accent3-l': '37%',
      '--accent3-op-min': 0.8,
      '--accent3-op-max': 0.4,
      '--error-h': 0,
      '--error-s': '100%',
      '--error-l': '50%',
      '--error-op-min': 0.6,
      '--error-op-max': 0.4,
      '--disabled-h': 0,
      '--disabled-s': '0%',
      '--disabled-l': '30%',
      '--disabled-op-min': 0.8,
      '--disabled-op-max': 0.4,
    }
  ),

  // VIDEO OVERLAY THEME
  new ThemeModel(
    'o00001',
    'overlay',
    {
      '--font': 'Play',
      '--bg-h': 0,
      '--bg-s': '0%',
      '--bg-l': '15.7%',
      '--bg-op': 0,
      '--bg-accent': '-2%',
      '--bg-accent-small': '-2%',
      '--bg-darken': '-40%',
      '--bg-op-overlay': 0.6,
      '--bg-op-min': 0.8,
      '--bg-op-max': 0.2,
      '--color-h': 0,
      '--color-s': '0%',
      '--color-l': '87.8%',
      '--color-accent': '10%',
      '--color-op-min': 0.8,
      '--color-op-max': 0.6,
      '--primary-h': 104,
      '--primary-s': '100%',
      '--primary-l': '64.7%',
      '--primary-op-min': 0.8,
      '--primary-op-max': 0.4,
      '--accent-h': 19,
      '--accent-s': '100%',
      '--accent-l': '52.2%',
      '--accent-op-min': 0.95,
      '--accent-op-max': 0.4,
      '--accent2-h': 199,
      '--accent2-s': '77.4%',
      '--accent2-l': '74%',
      '--accent2-op-min': 0.8,
      '--accent2-op-max': 0.4,
      '--accent3-h': 207,
      '--accent3-s': '98%',
      '--accent3-l': '37%',
      '--accent3-op-min': 0.8,
      '--accent3-op-max': 0.4,
      '--error-h': 60,
      '--error-s': '100%',
      '--error-l': '50%',
      '--error-op-min': 0.6,
      '--error-op-max': 0.4,
      '--disabled-h': 0,
      '--disabled-s': '0%',
      '--disabled-l': '30%',
      '--disabled-op-min': 0.8,
      '--disabled-op-max': 0.4,
    }
  ),

  // SRO EMBED THEME
  new ThemeModel(
    's00001',
    'sro',
    {
      '--font': 'Play',
      '--bg-h': 0,
      '--bg-s': '0%',
      '--bg-l': '100%',
      '--bg-op': 1,
      '--bg-accent': '-6%',
      '--bg-accent-small': '-2%',
      '--bg-darken': '-40%',
      '--bg-op-overlay': 0.4,
      '--bg-op-min': 0.84,
      '--bg-op-max': 0.2,
      '--color-h': 0,
      '--color-s': '0%',
      '--color-l': '13.4%',
      '--color-accent': '-10%',
      '--color-op-min': 0.8,
      '--color-op-max': 0.6,
      '--primary-h': 1,
      '--primary-s': '100%',
      '--primary-l': '50%',
      '--primary-op-min': 0.8,
      '--primary-op-max': 0.4,
      '--accent-h': 0,
      '--accent-s': '100%',
      '--accent-l': '50%',
      '--accent-op-min': 0.95,
      '--accent-op-max': 0.2,
      '--accent2-h': 199,
      '--accent2-s': '77.4%',
      '--accent2-l': '74%',
      '--accent2-op-min': 0.8,
      '--accent2-op-max': 0.4,
      '--accent3-h': 207,
      '--accent3-s': '98%',
      '--accent3-l': '37%',
      '--accent3-op-min': 0.8,
      '--accent3-op-max': 0.4,
      '--error-h': 60,
      '--error-s': '100%',
      '--error-l': '50%',
      '--error-op-min': 0.6,
      '--error-op-max': 0.4,
      '--disabled-h': 0,
      '--disabled-s': '0%',
      '--disabled-l': '30%',
      '--disabled-op-min': 0.8,
      '--disabled-op-max': 0.4,
    }
  ),
];
