import {Component} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {LoadingService} from '../_services/loading.service';

@Component({
  animations: [
    trigger('preloaderActivate', [
      state('active', style({visibility: 'visible', opacity: 1})),
      state('inactive', style({visibility: 'hidden', opacity: 0})),
      transition('* => active', [
        style({visibility: 'visible', opacity: 1}),
        animate('0.4s'),
      ]),
      transition('active => *', [
        animate('0.4s', style({visibility: 'hidden', opacity: 0})),
      ]),
    ]),
  ],
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})

export class LoadingComponent {

  isActive = false;
  mostRecent = '';

  constructor(
    private loadingService: LoadingService,
  ) {
    this.loadingService.onNewLoading()
      .subscribe(
        (newLoading) => {
          this.isActive = newLoading.length > 0;
          this.mostRecent = newLoading[newLoading.length - 1] || '';
        }
      );
  }

}
