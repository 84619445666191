<div class="email-actions">

  <div class="email-actions__content">

    <div *ngIf="!actionMode">
      <h1 class="accent">Oops!</h1>
      <h5>don't know how you ended up here</h5><br>
      <h5>but that's not supposed to happen</h5>
      <br>
      <button class="accent" (click)="onReload('')">
        back to home
      </button>
    </div>

    <div *ngIf="actionMode === ACTION_MODE_VERIFYEMAIL">
      <h1>Thanx for verifying your email!</h1>
      <br>
      <button class="accent" (click)="onReload('/account')">
        go to your account
      </button>
    </div>

    <div *ngIf="actionMode === ACTION_MODE_RESETPASSWORD">
      <h2>Please enter your new password</h2>
      <mat-form-field>
        <input name="newpw"
               type="password"
               matInput
               [(ngModel)]="newPw"
               autocapitalize="off"
               required
               minlength="1"
               placeholder="New Password">
      </mat-form-field>
      <br>
      <button class="accent" (click)="saveNewPassword()">save password</button>
    </div>
  </div>

  <app-bg [bgName]="'silverstone_pit'" [bgX]="0" [bgPortrait]="true"></app-bg>

</div>
