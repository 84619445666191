import {ContactComponent} from './components/contact/contact.component';
import {EmailActionsComponent} from './components/email-actions/email-actions.component';
import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {RouteDataGuard} from './shared/_guards/route-data.guard';
import {HomeComponent} from './components/home/home.component';
import {BlogComponent} from './components/blog/blog.component';

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  // anchorScrolling: 'enabled',
  scrollOffset: [0, 24],
  relativeLinkResolution: 'legacy'
};

const routes: Routes = [
  // EAGER COMPONENTS
  {
    data: {mm: 'blog'},
    component: BlogComponent,
    path: 'blog',
    resolve: {rtData: RouteDataGuard},
  },
  {
    data: {mm: 'blog'},
    component: BlogComponent,
    path: 'blog/:post_id',
    resolve: {rtData: RouteDataGuard},
  },
  {
    component: ContactComponent,
    path: 'contact',
    resolve: {rtData: RouteDataGuard},
  },
  {
    component: EmailActionsComponent,
    path: 'email-actions',
    resolve: {rtData: RouteDataGuard},
  },
  {
    component: HomeComponent,
    data: {mm: 'mm-intro'},
    path: 'home',
    resolve: {rtData: RouteDataGuard},
  },
  // LAZY MODULES
  {
    loadChildren: () => import('./components/account/account.module').then(m => m.AccountModule),
    path: 'account',
    resolve: {rtData: RouteDataGuard},
  },
  {
    loadChildren: () => import('./components/admin/admin.module').then(m => m.AdminModule),
    path: 'admin',
    resolve: {rtData: RouteDataGuard},
  },
  {
    loadChildren: () => import('./components/features/features.module').then(m => m.FeaturesModule),
    data: {mm: 'features'},
    path: 'features',
    resolve: {rtData: RouteDataGuard},
  },
  {
    loadChildren: () => import('./components/how-it-works/how-it-works.module').then(m => m.HowItWorksModule),
    data: {mm: 'how it works'},
    path: 'how-it-works',
    resolve: {rtData: RouteDataGuard},
  },
  {
    loadChildren: () => import('./components/legal/legal.module').then(m => m.LegalModule),
    path: 'legal',
    resolve: {rtData: RouteDataGuard},
  },
  {
    loadChildren: () => import('./components/pay/pay.module').then(m => m.PayModule),
    path: 'payment',
    resolve: {rtData: RouteDataGuard},
  },
  {
    loadChildren: () => import('./components/products/products.module').then(m => m.ProductsModule),
    data: {mm: 'products'},
    path: 'products',
    resolve: {rtData: RouteDataGuard},
  },
  {
    loadChildren: () => import('./components/server-request/server-request.module').then(m => m.ServerRequestModule),
    path: 'server-request',
    resolve: {rtData: RouteDataGuard},
  },
  {
    loadChildren: () => import('./components/timing/timing.module').then(m => m.TimingModule),
    path: 'timing',
  },
  // TODO: 23/02/2022 remove later on, is just to transition the old direct link way
  {
    loadChildren: () => import('./components/timing/timing.module').then(m => m.TimingModule),
    path: 'watch',
  },
  // REDIRECTS
  {path: '', pathMatch: 'full', redirectTo: 'home'},
  {path: '**', redirectTo: 'home'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
