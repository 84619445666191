<div class="swiper-pag {{cssClass}}">
  <div [class.disabled]="swiperIndex === 0"
       (click)="goToSlide(swiperIndex - 1)">
    <svg viewBox="0 0 24 24">
      <use [attr.xlink:href]="'assets/icons/arrow_left.svg#svg1'"></use>
    </svg>
  </div>
  <ng-container *ngFor="let item of list; let idx = index;">
    <app-hexagon [hexWithFill]="swiperIndex === idx"
                 [hexClass]="cssClass"
                 [hexSize]="24"
                 [hexStrokeWidth]="strokeWidth"
                 [matTooltip]="item"
                 [matTooltipClass]="'bg-' + cssClass"
                 style="margin: 0 7px;"
                 (click)="goToSlide(idx)">
    </app-hexagon>
  </ng-container>
  <div [class.disabled]="swiperIndex === list.length - 1"
       (click)="goToSlide(swiperIndex + 1)">
    <svg viewBox="0 0 24 24">
      <use [attr.xlink:href]="'assets/icons/arrow_right.svg#svg1'"></use>
    </svg>
  </div>
</div>
