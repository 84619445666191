import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {debounceTime, map, takeUntil} from 'rxjs/operators';
import {fromEvent, Observable, ReplaySubject, Subject, timer} from 'rxjs';
import {Injectable} from '@angular/core';
import {ViewportScroller} from '@angular/common';
import {VP_MOBILE_BREAKPOINT, VpModel} from '../_models/vp.model';
import {FuncsService} from './funcs.service';

@Injectable({
  providedIn: 'root'
})
export class VpService {

  private isPortrait: boolean; // DO NOT INITIALISE (ON MOBILE, I WANT IT TO FIRE ONCE WHEN ADDRESS BAR DISAPPEARS)

  private vp: VpModel = {isDesktop: true, winHeight: window.innerHeight, winWidth: window.innerWidth, isMobile: false};
  private vp$ = new ReplaySubject<VpModel>(1);
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private breakpointObserver: BreakpointObserver,
    private viewportScroller: ViewportScroller,
  ) {
    this.onResize();
    this.onIsDesktop();
  }

  private onResize(): void {
    fromEvent(window, 'resize')
      .pipe(
        takeUntil(this.onDestroy$),
        debounceTime(200),
        map(() => window.innerHeight),
      )
      .subscribe((e) => {
        const isPortrait = window.innerHeight > window.innerWidth;
        if (this.vp.isDesktop || isPortrait !== this.isPortrait) {
          this.isPortrait = isPortrait;
          const winHeight = Math.max(e, 375);
          const winWidth = Math.max(window.innerWidth, 375);
          if (winHeight !== this.vp.winHeight || winWidth !== this.vp.winWidth) {
            this.vp.winHeight = winHeight;
            this.vp.winWidth = winWidth;
            this.setMobile();
            this.newVp();
          }
        }
      });
  }

  private onIsDesktop(): void {
    this.breakpointObserver.observe([
      Breakpoints.Web,
    ])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (state) => {
          this.vp.isDesktop = state.matches;
          this.setMobile();
          this.newVp();
        });
  }

  private setMobile(): void {
    this.vp.isMobile = this.vp.winWidth <= VP_MOBILE_BREAKPOINT;
  }

  scrollToFragment(fragment: string): void {
    if (fragment) {
      timer(20)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(() => {
          this.viewportScroller.scrollToAnchor(fragment);
        });
    }
  }

  // ********************************************************************************************************
  // BROADCAST DATA
  // ********************************************************************************************************

  newVp() {
    this.vp$.next(this.vp);
  }

  onNewVp(): Observable<VpModel> {
    return this.vp$.asObservable();
  }

  getVp(): VpModel {
    return FuncsService.copy(this.vp);
  }

}
