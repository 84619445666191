import * as moment from 'moment';
import {AuthService} from '../../../shared/_services/auth.service';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {HintLsModel, HintModel, Hints, HintType} from '../_models/hint.model';
import {Injectable, OnDestroy} from '@angular/core';
import {LsService} from '../../../shared/_services/ls.service';
import {Platform} from '@angular/cdk/platform';
import {takeUntil} from 'rxjs/operators';
import {UserModel} from '../../../shared/_models/user.model';
import {ProductLevels} from '../../../shared/_models/product.model';
import {ServersService} from '../../../shared/_services/servers.service';
import {EmbedModeService} from '../../../shared/_services/embed-mode.service';

@Injectable({
  providedIn: 'root'
})
export class HintService implements OnDestroy {

  private user: UserModel;
  private pubTs: moment.Moment;

  private hint$ = new BehaviorSubject<HintModel>(null);
  private hintLs: HintLsModel;

  private isEmbedded = false;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private authService: AuthService,
    private embedModeService: EmbedModeService,
    private lsService: LsService,
    private platform: Platform,
    private serversService: ServersService,
  ) {
    this.getUser();
    this.getEmbedMode();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  // ********************************************************************************************************
  // LOAD DATA
  // ********************************************************************************************************

  private getUser(): void {
    this.authService.onNewUserWsSettings()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((user) => {
        this.user = user;
      });
  }


  private getEmbedMode(): void {
    this.embedModeService.onNewEmbedMode()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((embedMode) => {
        this.isEmbedded = embedMode.type !== 'none';
      });
  }

  // ********************************************************************************************************
  // BROADCAST DATA
  // ********************************************************************************************************

  newHint(hintType: HintType, meta: any = null) {
    let newHint: HintModel;
    if (hintType) {
      newHint = Hints.get(hintType);
      // HINT ALREADY ACKNOWLEDGED ? DO NOTHING
      if (this.isHintAcknowledged(newHint.type)) {
        return;
      }
      // ONGOING HINT IS OF HIGHER LEVEL THAN NEW HINT OR PUB ONGOING AND NEW PUB? DO NOTHING
      const curHint = this.getHint();
      if (curHint && (curHint.level > newHint.level)) {
        return;
      }
      // SET EXTRA METADATA IF PROVIDED
      if (meta) {
        newHint.meta = meta;
      }
    }
    // no hints in embedded mode or for demo servers
    const activeServer = this.serversService.getActiveServer();
    if (this.isEmbedded || (activeServer?.id.startsWith('demo') && hintType !== 'new-version')) {
      newHint = null;
    }
    this.hint$.next(newHint);
  }

  onNewHint(): Observable<HintModel> {
    return this.hint$.asObservable();
  }

  getHint(): HintModel {
    return this.hint$.getValue();
  }

  acknowledgeHint(): void {
    const hint = this.getHint();
    this.lsService.setItem('hint-' + hint.type, <HintLsModel>{
      retriesDone: this.hintLs.retriesDone + 1,
      nextRetry: moment().add(1, hint.retryInterval).toISOString()
    });
    this.newHint(null);
  }

  isHintAcknowledged(hintType: HintType): boolean {
    const hint = Hints.get(hintType);
    this.hintLs = new HintLsModel(this.lsService.getItem('hint-' + hintType) || {});
    return hint.numRetries >= 0 && (this.hintLs.retriesDone > hint.numRetries || moment().isBefore(moment(this.hintLs.nextRetry)));
  }

}
