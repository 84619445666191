import {Component, Input, OnDestroy} from '@angular/core';
import {HintService} from '../_services/hint.service';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-default-hint',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss']
})
export class DefaultComponent implements OnDestroy {

  @Input() hintLevel = 0;

  // DESTROY SUBJECT
  onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private hintService: HintService,
  ) {
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  acknowledgeHint(): void {
    this.hintService.acknowledgeHint();
  }

}
