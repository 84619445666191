import {ThemeModel, Themes, ThemeType} from '../_models/theme.model';
import {Injectable} from '@angular/core';
import {LsService} from './ls.service';
import {Observable, ReplaySubject} from 'rxjs';
import {EmbedModeService} from './embed-mode.service';
import {EmbedModeModel, EmbedModeParentType} from '../_models/embed-mode.model';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  private activeTheme: ThemeModel;
  private activeTheme$ = new ReplaySubject<ThemeModel>(1);
  private LS_THEME = 'activeTheme';

  constructor(
    private embedModeService: EmbedModeService,
    private lsService: LsService,
  ) {
    // this.getEmbedMode();
  }

  private getEmbedMode(): void {
    this.embedModeService.onNewEmbedMode()
      .subscribe(
        (embedMode) => {
          let themeName: ThemeType = this.lsService.getItem(this.LS_THEME);
          if (embedMode.type === 'overlay') {
            themeName = 'o00001';
          } else if (embedMode.type === 'iframe') {
            themeName = 's00001';
          }
          this.setActiveTheme(themeName, embedMode);
        }
      );
  }

  initTheme(): void {
   this.getEmbedMode();
  }

  private setActiveTheme(theme: ThemeType, embedMode: EmbedModeModel): void {
    let activeTheme = Themes.find((t) => t.code === theme);
    if (!activeTheme) {
      activeTheme = Themes.find((t) => t.code === 'd00001');
    }
    if (embedMode.type === 'none') {
      // save active theme if not in any embed mode
      this.lsService.setItem(this.LS_THEME, activeTheme.code);
    }
    this.activeTheme = activeTheme;
    Object.keys(this.activeTheme.properties).forEach(property => {
      this.updateDoc(property);
    });
    if (embedMode.parent) {
      this.applyCustomIframeSettings(embedMode.parent);
    }
    this.newTheme();
  }

  private updateDoc(prop: string): void {
    document.documentElement.style.setProperty(prop, this.activeTheme.properties[prop]);
  }

  private applyCustomIframeSettings(parent: EmbedModeParentType): void {
    switch (parent) {
      case 'sro':
        this.activeTheme.properties['--font'] = 'Exo2';
        this.updateDoc('--font');
        return;
    }
  }

  // ********************************************************************************************************
  // BROADCAST DATA
  // ********************************************************************************************************

  private newTheme() {
    this.activeTheme$.next(this.activeTheme);
  }

  onNewActiveTheme(): Observable<ThemeModel> {
    return this.activeTheme$.asObservable();
  }

  getActiveTheme(): ThemeModel {
    return this.activeTheme;
  }

}
