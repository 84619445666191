import * as moment from 'moment';
import {Component} from '@angular/core';
import {environment} from '../environments/environment';
import {ServersService} from './shared/_services/servers.service';
import {SwUpdate, VersionReadyEvent} from '@angular/service-worker';
import {HintService} from './components/hint/_services/hint.service';
import {ThemeService} from './shared/_services/theme.service';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(
    private hintService: HintService,
    private serversService: ServersService,
    private swUpdate: SwUpdate,
    private themeService: ThemeService,
  ) {
    this.themeService.initTheme();
    // SET DEFAULT LOCALE OF MOMENT TO 'EN' AND UPDATE IT'S DATE FORMATTING
    moment.locale('en');
    moment.updateLocale('en', {
      longDateFormat: {
        LT: 'h:mm A',
        LTS: 'h:mm:ss A',
        L: 'YYYY-MM-DD',
        l: 'YYYY-M-D',
        LL: 'MMMM Do YYYY',
        ll: 'MMM D YYYY',
        LLL: 'MMMM Do YYYY LT',
        lll: 'MMM D YYYY LT',
        LLLL: 'dddd, MMMM Do YYYY LT',
        llll: 'ddd, MMM D YYYY LT'
      }
    });
    // LISTEN FOR NEW VERSIONS
    if (environment.production) {
      this.subscribeToNewVersion();
    }
  }

  private subscribeToNewVersion(): void {
    this.swUpdate.versionUpdates
      .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
      .subscribe(
        () => {
          this.hintService.newHint('new-version');
        }
      );
  }

}
