import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {AuthService} from '../_services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let headers = req.headers;
    if (req.method !== 'GET') {
      headers = headers.set('Content-type', 'application/json');
    }

    return this.authService.getToken().pipe(
      mergeMap((token) => {
        headers = headers.set('Authorization', `Bearer ${token}`);
        const authRequest = req.clone({headers: headers});
        return next.handle(authRequest);
      })
    );

  }

}
