export class ActiveRouteModel {
  params: any;
  queryParams: any;
  url: string;

  constructor(url: string, queryParams: any, params: any) {
    this.params = params || {};
    this.queryParams = queryParams || {};
    this.url = url;
  }

}
