import * as moment from 'moment';
import DurationConstructor = moment.unitOfTime.DurationConstructor;

export declare type HintType = '' | 'install' | 'no-server' | 'level' | 'new-version' | 'no-email';
export declare type HintLevelType = 1 | 2 | 3 | 4; // 'info' | 'help' | 'warning' | 'must';

// SET NUMRETRIES TO -1 FOR INFINITE
export const Hints = new Map()
  .set('install', <HintModel>{type: 'install', level: 1, numRetries: 0})
  .set('level', <HintModel>{type: 'level', level: 1, numRetries: -1})
  .set('new-version', <HintModel>{type: 'new-version', level: 4, numRetries: -1})
  .set('no-email', <HintModel>{type: 'no-email', level: 4, numRetries: -1})
  .set('no-server', <HintModel>{type: 'no-server', level: 3, numRetries: 0})
;

export class HintModel {
  level: HintLevelType;
  numRetries: number;
  retryInterval?: DurationConstructor;
  meta?: any;
  type: HintType;
}

export class HintLsModel {
  nextRetry: string;
  retriesDone: number;

  constructor(objIn: any) {
    this.nextRetry = objIn?.nextRetry || '';
    this.retriesDone = objIn?.retriesDone ? Number(objIn.retriesDone) : 0;
  }

}

