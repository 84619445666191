import {Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {ProductLevels, ProductModel, ProductType} from '../_models/product.model';
import {ProductsService} from '../_services/products.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.scss']
})
export class PriceComponent implements OnInit, OnChanges, OnDestroy {

  @Input() productType: ProductType = 'free';
  @Input() level = 0;
  @Input() forcePrice = 0;

  product: ProductModel;
  productCssClass = 'free';
  userHasProduct = false;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private productsService: ProductsService,
  ) {
  }

  ngOnInit() {
    this.onProducts();
  }

  ngOnChanges() {
    this.userHasProduct = this.level === ProductLevels[this.productType];
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  // ********************************************************************************************************
  // LOAD DATA
  // ********************************************************************************************************

  private onProducts(): void {
    this.productsService.onNewProducts()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (products) => {
          if (products?.length) {
            this.product = this.productsService.getProductByType(this.productType);
            this.productCssClass = this.productType.replace('+', '-plus');
          }
        }
      );
  }

}
