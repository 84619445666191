import {DefaultComponent} from './default/default.component';
import {HintComponent} from './hint/hint.component';
import {NgModule} from '@angular/core';
import {PwaInstallComponent} from './pwa-install/pwa-install.component';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../../shared/shared.module';
import {NewVersionComponent} from './new-version/new-version.component';

@NgModule({
  declarations: [
    DefaultComponent,
    HintComponent,
    NewVersionComponent,
    PwaInstallComponent,
  ],
  exports: [
    HintComponent,
  ],
  imports: [
    SharedModule,
    RouterModule,
  ],
  providers: [],
})
export class HintModule {
}
