import {Injectable, OnDestroy} from '@angular/core';
import {LsService} from './ls.service';
import {Observable, ReplaySubject, Subject, timer} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {EmbedModeService} from './embed-mode.service';

@Injectable({
  providedIn: 'root'
})
export class CookiesService implements OnDestroy {

  private readonly LS_COOKIES = 'hasAcceptedCookies';
  private hasAccepted$ = new ReplaySubject<boolean>(1);

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private lsService: LsService,
    private embedModeService: EmbedModeService,
  ) {
    this.setHasAccepted();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private setHasAccepted(): void {
    timer(4000)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        const mode = this.embedModeService.getEmbedMode();
        this.hasAccepted$.next(mode.type !== 'none' || this.lsService.getItem(this.LS_COOKIES) === 'true');
      });
  }

  acceptCookies(): void {
    this.lsService.setItem(this.LS_COOKIES, true);
    this.hasAccepted$.next(true);
  }

  revokeCookies(): void {
    this.lsService.removeItem(this.LS_COOKIES);
    this.hasAccepted$.next(false);
  }

  onHasAccepted(): Observable<boolean> {
    return this.hasAccepted$.asObservable();
  }

}
