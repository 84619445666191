<div class="footer">

  <!-- partners -->
  <div class="footer__section">
    <div class="footer__section__block" style="width: 100%;">
      <h3>official partners</h3>
    </div>
    <!-- simgrid -->
    <div class="footer__section__block flex col center partner">
      <a href="https://thesimgrid.com" target="_blank" rel="noopener" aria-label="link to simgrid">
        <img class="logo-simgrid" src="assets/images/partners/simgrid.png" alt="simgrid logo" width="720" height="150"/>
      </a>
    </div>
    <!-- timeservice -->
    <div class="footer__section__block flex col center partner">
      <a href="https://timeservice.nl" target="_blank" rel="noopener" aria-label="link to timeservice">
        <img class="logo-timeservice" src="assets/images/partners/timeservice.png" alt="timeservice logo" width="676" height="208"/>
      </a>
    </div>
    <!-- its events -->
    <div class="footer__section__block flex col center partner">
      <a href="https://www.its-events.com" target="_blank" rel="noopener" aria-label="link to its events">
        <img class="logo-its" src="assets/images/partners/its-events.png" alt="its events logo" width="720" height="180"/>
      </a>
    </div>
    <!-- its chrono -->
    <div class="footer__section__block flex col center partner">
      <a href="https://www.itschrono.com" target="_blank" rel="noopener" aria-label="link to its chrono">
        <img class="logo-its" src="assets/images/partners/its-chrono.png" alt="its events logo" width="720" height="210"/>
      </a>
    </div>
  </div>

  <!-- logo, site-map && socials -->
  <div class="footer__section">

    <div class="footer__section__block flex col center">
      <picture>
        <source type="image/webp" srcset="assets/images/pitwall_logo.webp">
        <source type="image/jpeg" srcset="assets/images/pitwall_logo.png">
        <img class="logo-pitwall" src="assets/images/pitwall_logo.png" alt="pitwall logo" width="1280" height="370" routerLink="/home"/>
      </picture>
    </div>

    <div class="footer__section__block sitemap">
      <div routerLink="/home" routerLinkActive="primary"><span class="hover">home</span></div>
      <div routerLink="/timing" routerLinkActive="primary"><span class="hover">live-timing</span></div>
      <div routerLink="/how-it-works" routerLinkActive="primary"><span class="hover">how it works</span></div>
      <div routerLink="/features" routerLinkActive="primary"><span class="hover">features</span></div>
      <div routerLink="/products" routerLinkActive="primary"><span class="hover">products</span></div>
      <div routerLink="/account" routerLinkActive="primary"><span
        class="hover">{{isSignedIn ? 'your account' : 'register / sign in'}}</span></div>
      <div routerLink="/contact" routerLinkActive="primary"><span class="hover">contact</span></div>
    </div>

    <div class="footer__section__block flex col center">

      <div>

        <!-- DISCORD -->
        <div class="footer__section__block__s hover">
          <a href="https://discord.gg/trBnZke" target="_blank" rel="noopener">
            <svg viewBox="0 0 24 24">
              <use [attr.xlink:href]="'assets/icons/soc/dscrd.svg#svg1'"></use>
            </svg>
          </a>
        </div>

        <!-- FB -->
        <div class="footer__section__block__s hover">
          <a (click)="goTo('fb')" rel="noopener">
            <svg viewBox="0 0 24 24">
              <use [attr.xlink:href]="'assets/icons/soc/fb.svg#svg1'"></use>
            </svg>
          </a>
        </div>

        <!-- TWITTER -->
        <div class="footer__section__block__s hover">
          <a (click)="goTo('tw')" rel="noopener">
            <svg viewBox="0 0 24 24">
              <use [attr.xlink:href]="'assets/icons/soc/tw.svg#svg1'"></use>
            </svg>
          </a>
        </div>

        <!-- INSTA -->
        <div class="footer__section__block__s hover">
          <a (click)="goTo('nsta')" rel="noopener">
            <svg viewBox="0 0 24 24">
              <use [attr.xlink:href]="'assets/icons/soc/nsta.svg#svg1'"></use>
            </svg>
          </a>
        </div>

      </div>

    </div>

  </div>

  <!-- legal -->
  <div class="footer__section">

    <div class="footer__section__block">
      <span class="link" routerLink="/legal/terms-conditions">terms & conditions</span>
    </div>
    <div class="footer__section__block">
      v{{appVersion}} &copy; <a href="https://pit-it.net" target="_blank" rel="noopener">Speed Capital Ltd</a>. All rights
      reserved
    </div>
    <div class="footer__section__block">
      <span class="link" routerLink="/legal/privacy">privacy policy</span>
    </div>

  </div>

</div>
