<div class="hint"
     [class.visible]="hintActive"
     [class.big]="hint?.type === 'pub'">

  <div class="hint__content">
    <ng-container [ngSwitch]="hint?.type">

      <!-- LEVEL -->
      <app-default-hint *ngSwitchCase="'level'">
        This server includes access to our {{hint?.meta | uppercase}} features. Enjoy!
      </app-default-hint>

      <!-- PWA INSTALL -->
      <app-pwa-install *ngSwitchCase="'install'"></app-pwa-install>

      <!-- NO ACTIVE SERVER SELECTED -->
      <app-default-hint *ngSwitchCase="'no-server'">
        First time here?<br>
        <b>Select a server from the list to get started!</b><br><br>
        Or check out our <a href="https://www.youtube.com/channel/UCZKiCd42K8KaieZIsWHwWhA" target="_blank"
        rel="noopener">youtube channel</a> for tutorials.
      </app-default-hint>

      <!-- NEW VERSION -->
      <app-new-version *ngSwitchCase="'new-version'"></app-new-version>

      <!-- NO EMAIL -->
      <app-default-hint *ngSwitchCase="'no-email'">
        You signed in with a Facebook account without an email linked to it, or you denied pitwall.live access
        to it<br><br>
        Please add an email address to your Facebook account or fix pitwall.live's permissions to see your linked email<br><br>
        If you do not wish to do that, log in with email or google instead
      </app-default-hint>

    </ng-container>
  </div>

</div>
