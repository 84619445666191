import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';

@Component({
  selector: 'app-swiper-pag',
  templateUrl: './swiper-pag.component.html',
  styleUrls: ['./swiper-pag.component.scss']
})
export class SwiperPagComponent implements OnChanges {

  @Input() cssClass = 'primary';
  @Input() list: string[];
  @Input() strokeWidth = 20;
  @Input() swiperIdx: number;

  @Output() onPagination = new EventEmitter<number>();

  swiperIndex = 0;

  ngOnChanges(): void {
    this.swiperIndex = Math.max(this.swiperIdx, 0);
  }

  goToSlide(idx: number): void {
    this.onPagination.emit(idx);
  }

}
