import {Observable, throwError} from 'rxjs';
import {Injectable} from '@angular/core';
import {ErrorMessage} from '../_models/error.model';
import {HttpErrorResponse} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  public handleError(error: HttpErrorResponse): Observable<ErrorMessage> {

    const err: ErrorMessage = {Code: 0, Message: ''};

    if (error.message && !error.error) {
      err.Code = error.status || 9999;
      err.Message = error.message;
    } else if (!error.status || error.status === 0) {
      // SERVER IS NOT RUNNING, NO REAL ERROR RESPONSE RECEIVED
      err.Code = 9999;
      err.Message = 'Connection failed. Are you sure you are connected to the internet?';
    } else {
      err.Code = error.status;
      err.Message = error.statusText || error.message;

      // SPECIFIC FOR DB ERROR
      if (error.error) {
        if (error.error.hasOwnProperty('msg') && error.error.msg !== '') {
          err.Message = error.error.msg;
        } else if (error.error !== '') {
          err.Message = error.error;
        }
      }

    }
    // RETURN OBSERVABLE
    return throwError(() => err);
  }

}
