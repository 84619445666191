import * as moment from 'moment';

export type ProductPeriodType = 'month' | 'year';

export class ProductModel {
  cancelAtPeriodEnd: boolean; // for products stored in user profiles
  endDate: string; // for products stored in user profiles
  inCart: boolean; // for check-out
  label: string;
  level: number;
  name: ProductType;
  period: ProductPeriodType;
  price: number;
  priceId: string;
  subscriptionId: string; // for products stored in user profiles

  constructor(objIn: any) {
    this.cancelAtPeriodEnd = false;
    this.endDate = '';
    this.inCart = false;
    this.label = objIn.Label || objIn.Name || '';
    this.level = objIn.Level || 0;
    this.name = objIn.Name || '';
    this.period = objIn.Period || 'year';
    this.price = objIn.Price || 0;
    this.priceId = objIn.PriceId || '';
    this.subscriptionId = objIn.SubscriptionId || '';
  }

  static checkIfActive(endDate: string, isToCancel: boolean = false): boolean {
    return (!isToCancel && moment().isBefore(moment(endDate).add(2, 'days'))) ||
           (isToCancel && moment().isBefore(endDate));
  }

}

export type ProductType =
  'anonymous' |
  'free' |
  'lite' |
  'pro' |
  'pro+' |
  'expert' |
  'serverCommunity' |
  'serverPartner';


export const ProductLevels = {
  anonymous: 0,
  free: 1,
  lite: 2,
  pro: 3,
  'pro+': 4,
  expert: 5,
};

// this is just used for the dashboards & dashboard level display
export const ProductLevelsDesc = {
  0: 'anonymous',
  1: 'free',
  2: 'lite',
  3: 'pro',
  4: 'pro',
  5: 'expert',
};
