import {AuthService} from '../_services/auth.service';
import {Component, OnDestroy} from '@angular/core';
import {MmService} from '../_services/mm.service';
import {Subject, timer} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {EmbedModeService} from '../_services/embed-mode.service';

@Component({
  selector: 'app-mm',
  templateUrl: './mm.component.html',
  styleUrls: ['./mm.component.scss']
})

export class MmComponent implements OnDestroy {

  isSignedIn = false;
  mmActive: boolean;
  mmState = '';
  mmIntro = false;

  isEmbedded = false;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private authService: AuthService,
    private mmService: MmService,
    private embedModeService: EmbedModeService,
  ) {
    this.getSignedInState();
    this.getMmState();
    this.getEmbedMode();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  // ********************************************************************************************************
  // LOAD DATA
  // ********************************************************************************************************

  private getSignedInState(): void {
    this.authService.onNewUser()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((user) => {
        this.isSignedIn = !!user?.uid;
      });
  }

  private getMmState(): void {
    this.mmService.onNewMmState()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((state) => {
        // SPECIAL TREATMENT FOR MM-INTRO
        if (state === 'mm-intro') {
          this.mmIntro = true;
          this.mmState = '';
          timer(2400)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(() => {
              this.mmIntro = false;
            });
        } else {
          this.mmState = state;
        }
      });
  }

  private getEmbedMode(): void {
    this.embedModeService.onNewEmbedMode()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((embedMode) => {
        this.isEmbedded = embedMode?.type !== 'none';
      });
  }

  // ********************************************************************************************************
  // UI
  // ********************************************************************************************************

  // EVENT LISTENERS
  onToggleMenu(): void {
    this.mmActive = !this.mmActive;
  }

}
