import * as moment from 'moment';
import {AuthService} from './auth.service';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Injectable, OnDestroy} from '@angular/core';
import {LsService} from './ls.service';
import {SbService} from './sb.service';
import {takeUntil} from 'rxjs/operators';
import {UserModel} from '../_models/user.model';
import {WS_ERROR_RS, WsEventModel} from '../_models/ws.model';
import {ActiveRouteService} from './active-route.service';
import {FuncsService} from './funcs.service';

@Injectable({
  providedIn: 'root'
})
export class WsService implements OnDestroy {

  private LS_ANOMID = 'anomId';

  private user: UserModel;

  private wsCars$ = new BehaviorSubject<WsEventModel>(null);
  private wsEvent$ = new BehaviorSubject<WsEventModel>({t: null, d: null});
  private wsAddress$ = new BehaviorSubject<string>(null);
  private wsReadyState$ = new BehaviorSubject<number>(0);
  private wsSession$ = new BehaviorSubject<WsEventModel>(null);
  private wsTp$ = new BehaviorSubject<WsEventModel>(null);

  public ws: WebSocket = null;

  private noReconnect = false;
  private wsReconnCounter = 1;
  private wsReconnTimeOut: any;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private activeRouteService: ActiveRouteService,
    private authService: AuthService,
    private lsService: LsService,
    private sbService: SbService,
  ) {
    this.getUser();
    this.getWsUrl();
    this.getReadyState();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  // ********************************************************************************************************
  // LOAD DATA
  // ********************************************************************************************************

  private getWsUrl(): void {
    this.onNewWsAddress()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (url) => {
          // CHECK IF AUTH STATE HAS EMITTED ALREADY
          if (url && this.user !== undefined) {
            this.wsConnect();
          }
        }
      );
  }

  private getUser(): void {
    this.authService.onNewUser()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (user) => {
          // if nothing changed to the db.level, we don't care, that's all that matters for the ws
          // this was added to ignore the hourly firebase token refresh
          if (user?.db?.level === this.user?.db?.level) {
            return;
          }
          this.user = FuncsService.copy(user);
          if (this.getWsAddress()) {
            this.wsConnect();
          }
        }
      );
  }

  private getReadyState(): void {
    this.onNewWsReadyState()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (rs) => {
          if (rs === 1) {
            this.wsReconnCounter = 1;
          }
          if (rs === 3 && !this.noReconnect) {
            // WS DISCONNECTED: CLEAR SESSION AND CAR MATRIX
            this.newWsSession(null);
            this.newWsCars(new WsEventModel({d: []}));
            clearTimeout(this.wsReconnTimeOut);
            this.wsReconnTimeOut = setTimeout(() => {
              this.wsConnect();
              this.wsReconnCounter += 1;
            }, 1000 * (this.wsReconnCounter % 4));
          }
        }
      );
  }

  private wsConnect(): void {
    this.noReconnect = false;
    if (this.ws && this.ws.readyState === 1) {
      console.log('gonna close ws (current readystate: ' + this.ws?.readyState + ')');
      // WS OPEN: CLOSE FIRST AND LET AUTO-RECONNECT DO THE REST
      this.wsClose();
    } else if (!this.ws || this.ws.readyState === 3) {
      console.log('gonna open ws (current readystate: ' + this.ws?.readyState + ')');
      // NO WS OPEN: CONNECT
      const route = this.activeRouteService.getActiveRoute();
      if (this.user?.uid) {
        this.authService.getToken()
          .subscribe(
            (token) => {
              this.wsInit(token);
            });
      } else if (route?.queryParams.usr && route.queryParams.pwd) {
        this.wsInit(null, null, {usr: route.queryParams.usr, pwd: route.queryParams.pwd});
      } else {
        const anomId = this.lsService.getItem(this.LS_ANOMID) || Math.round(moment().unix() * Math.random());
        this.lsService.setItem(this.LS_ANOMID, anomId);
        this.wsInit(null, anomId);
      }
    }
  }

  private wsInit(token: string = null, anomId: string = null, bAuth: { usr: string, pwd: string } = null) {
    // CONNECT TO SOCKET
    let tmpUrl = 'wss://' + this.getWsAddress() + '/ws?v=' + environment.backVersion;
    if (token) {
      tmpUrl += '&token=' + token;
    } else if (bAuth) {
      // for browser sources
      tmpUrl += '&usr=' + bAuth.usr + '&pwd=' + bAuth.pwd;
    } else if (anomId) {
      tmpUrl += '&anomid=' + anomId;
    }
    this.ws = new WebSocket(tmpUrl);
    // CONNECT EVENT
    this.ws.onopen = () => {
      console.log('ws opened');
      this.newWsReadyState(this.ws.readyState);
    };
    // CLOSE EVENT
    this.ws.onclose = (e) => {
      // TODO: ONLY RECONNECT ON NOT CLEAN? IN WHICH CASE DOES THE B-E CLOSE CLEANLY (ON PURPOSE)??
      if (e.wasClean) {
        console.log('ws closed clean! ' + e.reason);
      } else {
        // SERVER PROCESS KILLED OR NETWORK DOWN, NO CLOSE FRAME RECEIVED
        // EVENT.CODE IS USUALLY 1006 IN THIS CASE
        console.log('ws closed:');
        console.log(e);
      }
      // FIRE READYSTATE ALSO ON CLEAN CLOSE: WHEN SWITCHING SERVERS, THE AUTO-RECONNECT WILL KICK IN WITH THE NEWLY SET PORT
      this.newWsReadyState(this.ws.readyState);
    };
    // ERROR EVENT
    this.ws.onerror = (err) => {
      // ERROR WILL FIRE CLOSE EVENT, SO DO NOTHING HERE
      console.log('ws error:' + err);
    };
    // MESSAGE EVENT
    this.ws.onmessage = (e) => {
      if (e.data) {
        const tmpMessage = JSON.parse(e.data);
        if (tmpMessage.hasOwnProperty('d') && tmpMessage.hasOwnProperty('t')) {
          const tmpResponse = new WsEventModel(tmpMessage);
          this.emitNewEvent(tmpResponse);
        }
      }
    };
  }

  private emitNewEvent(e: WsEventModel): void {
    // SOME EVENTS ARE PUSHED DIRECTLY TO A SERVICE
    // BECAUSE THEY ARE GLOBAL SERVICES THAT SHOULD BE INITIALISED TOGETHER WITH THE WS.SERVICE
    switch (e.t) {
      case 'auth':
        if (this.user?.db && e.d.hasOwnProperty('Flavour')) {
          this.user.ws.uid = this.user.uid || e.d.FB || '';

          // TODO: remove this!!!!
          if (this.user.ws.uid === '?' + this.lsService.getItem(this.LS_ANOMID)) {
            this.user.ws.uid = '';
          }

          this.user.ws.level = e.d.Flavour || 0;
          this.authService.newUserWsSettings(this.user.ws);
        }
        break;
      case 'session':
        this.newWsSession(e);
        break;
      case 'C':
        this.newWsCars(e);
        break;
      case 'p':
        this.newWsTp(e);
        break;
      case 'error':
        this.noReconnect = true;
        // broadcast fake rs so listening components know there was an error
        this.newWsReadyState(WS_ERROR_RS);
        // AVOID SHOWING ERRORS TO USERS WHO HAVE NOT VERIFIED THEIR EMAIL YET (THEY WILL ALSO GET UNAUTHORISED MSG ON WS)
        if (!(this.user?.withEmail && !this.user?.emailVerified)) {
          this.sbService.message(e.d);
        }
        break;
      default:
        this.newWsEvent(e);
        break;
    }
  }

  // CLOSE WS
  private wsClose(): void {
    if (this.ws && this.ws.readyState === 1) {
      this.ws.close(1000, 'switching to other server');
    }
  }

  // ********************************************************************************************************
  // BROADCAST DATA
  // ********************************************************************************************************

  // PORT
  newWsAddress(address: string): void {
    this.wsAddress$.next(address);
  }

  onNewWsAddress(): Observable<string> {
    return this.wsAddress$.asObservable();
  }

  getWsAddress(): string {
    return this.wsAddress$.getValue();
  }

  // READY STATE
  private newWsReadyState(rs: number): void {
    this.wsReadyState$.next(rs);
  }

  onNewWsReadyState(): Observable<number> {
    return this.wsReadyState$.asObservable();
  }

  // SESSION
  private newWsSession(wsE: WsEventModel): void {
    this.wsSession$.next(wsE);
  }

  onNewWsSession(): Observable<WsEventModel> {
    return this.wsSession$.asObservable();
  }

  // CARS
  private newWsCars(wsE: WsEventModel): void {
    this.wsCars$.next(wsE);
  }

  onNewWsCars(): Observable<WsEventModel> {
    return this.wsCars$.asObservable();
  }

  // TRACK POSITION (GPS COORDS)
  private newWsTp(wsE: WsEventModel): void {
    this.wsTp$.next(wsE);
  }

  onNewWsTp(): Observable<WsEventModel> {
    return this.wsTp$.asObservable();
  }

  // EVENTS IN GENERAL
  private newWsEvent(wsE: WsEventModel): void {
    this.wsEvent$.next(wsE);
  }

  onNewWsEvent(): Observable<WsEventModel> {
    return this.wsEvent$.asObservable();
  }

  // SEND COMMAND ON WS
  wsSendEvent(e: WsEventModel): void {
    if (this.ws && this.ws.readyState === 1) {
      this.ws.send(JSON.stringify(e));
    }
  }

}
