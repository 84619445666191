import {ActiveRouteService} from '../_services/active-route.service';
import {Injectable} from '@angular/core';
import {MmService} from '../_services/mm.service';
import {Observable, of} from 'rxjs';
import {Resolve, RouterStateSnapshot, ActivatedRouteSnapshot} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class RouteDataGuard implements Resolve<string> {

  constructor(
    private activeRouteService: ActiveRouteService,
    private mmService: MmService,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Observable<never> {

    this.activeRouteService.newActiveRoute(state.url, route.queryParams, route.params);

    if (route.data?.mm) {
      this.mmService.newMmState(route.data.mm);
    } else {
      this.mmService.newMmState('');
    }

    // JUST PASS THROUGH ROUTE.DATA CUZ WE ACTUALLY USE THIS GUARD TO BROADCAST DATA BEFORE NAVIGATING
    return of(route.data);
  }

}
