import * as moment from 'moment';
import {GaService} from './ga.service';
import {Injectable} from '@angular/core';
import {LsService} from './ls.service';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {Router} from '@angular/router';
import {SbService} from './sb.service';
import {take, takeUntil} from 'rxjs/operators';
import {UserModel, UserUiModel, UserWsModel} from '../_models/user.model';
import {UserService} from './user.service';
import {EmbedModeService} from './embed-mode.service';
import {UserLtsService} from './user-lts.service';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {DbService} from './db.service';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  private user$ = new ReplaySubject<UserModel>(1);
  // separate subject that fires once ws attributes are set, some components will only listen to this one
  private userWithWsSettings$ = new ReplaySubject<UserModel>(1);
  private user: UserModel;

  private isListening = false;
  private onStopListening$: Subject<void> = new Subject<void>();

  constructor(
    private afAuth: AngularFireAuth,
    private dbService: DbService,
    private embedModeService: EmbedModeService,
    private gaService: GaService,
    private lsService: LsService,
    private router: Router,
    private sbService: SbService,
    private userService: UserService,
    private userLtsService: UserLtsService,
  ) {
    this.onAfAuthState();
  }

  // ********************************************************************************************************
  // LOAD DATA
  // ********************************************************************************************************

  private onAfAuthState(): void {
    // SUBSCRIBE TO AUTHENTICATION STATE UPDATES
    this.afAuth.authState
      .subscribe((user) => {
        const embedMode = this.embedModeService.getEmbedMode();
        // do not listen to or broadcast user when in iframe (or it takes the settings of the user if he is logged into pitwall in that same browser)
        if (embedMode.type !== 'iframe' && user?.email) {
          this.gaService.sendEvent('login');
          this.user = new UserModel(user);
          if (user.providerData.some((p) => p.providerId.toLowerCase().includes('facebook')) || user.emailVerified) {
            // USER AUTHENTICATED WITH FACEBOOK OR VERIFIED HIS EMAIL: CHECK DB
            this.user.emailVerified = true;
            this.startUserListener();
          } else {
            this.newUser();
            // USER IS REGISTERED BUT NO EMAIL VERIFIED. REROUTE TO ACCOUNT SCREEN UNLESS THE ROUTE IS EMAIL-ACTIONS (FOR VERIFY EMAIL CALLBACK)
            if (window.location.pathname !== '/email-actions') {
              this.router.navigate(['/account']);
            }
          }
        } else {
          this.user = new UserModel({});
          this.newUser();
        }
      });
  }

  // GET AND/OR SET USER IN DB
  startUserListener(): void {
    if (!this.isListening) {
      this.isListening = true;
      this.dbService.listenToDoc(environment.db.users, this.user.uid)
        .pipe(
          takeUntil(this.onStopListening$)
        )
        .subscribe({
            next: (res) => {
              if (res) {
                this.user.db = new UserUiModel(res);
                this.newUser();
                // NO TIMESTAMP OR LONGER THAN MONTH AGO: UPDATE
                if (!this.user.db.ts || moment(this.user.db.ts).isBefore((moment().add(-1, 'week')))) {
                  this.updateUser();
                }
                // check if user has an lts
                this.userLtsService.callGetUserLts();
                // check if user has responded to the transfer opt-in. If not, route to account page
                if (!this.user?.db?.settings.hasRepliedToTransfer) {
                  this.router.navigate(['/account']);
                }
              } else {
                // NO USER YET, STOP LISTENING FOR CHANGES, AND CREATE USER ENTRY
                this.stopUserListener();
                this.createUser();
              }
            },
            error: (err) => {
              this.sbService.message(err);
              this.newUser();
            }
          }
        );
    }
  }

  stopUserListener(): void {
    console.log('stop listening to user');
    this.isListening = false;
    this.onStopListening$.next();
  }

  signOut(): void {
    this.userLtsService.resetLtsOwned();
    this.stopUserListener();
    this.afAuth.signOut().then(() => {
      // DO NOTHING
    }).catch((err) => {
      this.sbService.message(err.message);
    });
  }

  // ********************************************************************************************************
  // SAVE DATA
  // ********************************************************************************************************

  private createUser(): void {
    this.userService.createUser()
      .subscribe({
          next: () => {
            this.startUserListener();
            this.newUser();
          },
          error: (err) => {
            this.sbService.message(err);
            this.newUser();
          }
        }
      );
  }

  updateUser(): void {
    this.userService.updateUser(this.user.uid, this.user.db)
      .then(
        () => {
          console.log('updated user timestamp');
        },
      )
      .catch(
        (err) => {
          console.log('error updating user timestamp: ' + err);
        }
      );
  }

  // ********************************************************************************************************
  // BROADCAST DATA
  // ********************************************************************************************************

  // SIGNED IN STATE
  newUser() {
    this.user$.next(this.user);
  }

  onNewUser(): Observable<UserModel> {
    return this.user$.asObservable();
  }

  newUserWsSettings(wsSettings: UserWsModel) {
    this.user.ws = wsSettings;
    this.userWithWsSettings$.next(this.user);
  }

  onNewUserWsSettings(): Observable<UserModel> {
    return this.userWithWsSettings$.asObservable();
  }

  getUser(): UserModel {
    return this.user;
  }

  // GET TOKEN OF CURRENT USER (FOR INTERCEPTORS)
  getToken(): Observable<string> {
    return this.afAuth.idToken.pipe(take(1));
  }

}
