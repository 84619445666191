<!-- BURGER -->
<div *ngIf="!isEmbedded"
     id="mm-burger"
     [class.mm-intro]="mmIntro && !mmActive"
     [class.active]="mmActive"
     (click)="onToggleMenu()">
  <div>
    menu
  </div>
  <span></span>
  <span></span>
</div>

<!-- SITE TITLE -->
<div *ngIf="mmState"
     class="mm-side-title">
  {{mmState}}
</div>

<!-- MENU ITSELF -->
<div class="mm-content" [class.visible]="mmActive" [class.invisible]="!mmActive">

  <!-- MENU ITEMS -->
  <div class="mm-content__items">

    <div class="mm-content__items__item"
         [class.visible]="mmActive"
         [class.invisible]="!mmActive"
         routerLink="/home"
         (click)="onToggleMenu()">
      home
      <svg viewBox="0 0 24 24">
        <use [attr.xlink:href]="'assets/icons/arrow_right.svg#svg1'"></use>
      </svg>
    </div>

    <div class="mm-content__items__item"
         [class.visible]="mmActive"
         [class.invisible]="!mmActive"
         routerLink="/timing"
         (click)="onToggleMenu()">
      live-timing
      <svg viewBox="0 0 24 24">
        <use [attr.xlink:href]="'assets/icons/arrow_right.svg#svg1'"></use>
      </svg>
    </div>

    <div class="mm-content__items__item"
         [class.visible]="mmActive"
         [class.invisible]="!mmActive"
         routerLink="/how-it-works"
         (click)="onToggleMenu()">
      how it works
      <svg viewBox="0 0 24 24">
        <use [attr.xlink:href]="'assets/icons/arrow_right.svg#svg1'"></use>
      </svg>
    </div>

    <div class="mm-content__items__item"
         [class.visible]="mmActive"
         [class.invisible]="!mmActive"
         routerLink="/features"
         (click)="onToggleMenu()">
      features
      <svg viewBox="0 0 24 24">
        <use [attr.xlink:href]="'assets/icons/arrow_right.svg#svg1'"></use>
      </svg>
    </div>

    <div class="mm-content__items__item"
         [class.visible]="mmActive"
         [class.invisible]="!mmActive"
         routerLink="/products"
         (click)="onToggleMenu()">
      pricing
      <svg viewBox="0 0 24 24">
        <use [attr.xlink:href]="'assets/icons/arrow_right.svg#svg1'"></use>
      </svg>
    </div>

<!--    <div class="mm-content__items__item"-->
<!--         [class.visible]="mmActive"-->
<!--         [class.invisible]="!mmActive"-->
<!--         routerLink="/blog"-->
<!--         (click)="onToggleMenu()">-->
<!--      blog-->
<!--      <svg viewBox="0 0 24 24">-->
<!--        <use [attr.xlink:href]="'assets/icons/arrow_right.svg#svg1'"></use>-->
<!--      </svg>-->
<!--    </div>-->

    <div class="mm-content__items__item"
         [class.visible]="mmActive"
         [class.invisible]="!mmActive"
         routerLink="/contact"
         (click)="onToggleMenu()">
      contact
      <svg viewBox="0 0 24 24">
        <use [attr.xlink:href]="'assets/icons/arrow_right.svg#svg1'"></use>
      </svg>
    </div>

    <div class="mm-content__items__item"
         [class.visible]="mmActive"
         [class.invisible]="!mmActive"
         routerLink="/account"
         (click)="onToggleMenu()">
      {{isSignedIn ? 'your account' : 'register / sign in'}}
      <svg viewBox="0 0 24 24">
        <use [attr.xlink:href]="'assets/icons/arrow_right.svg#svg1'"></use>
      </svg>
    </div>

  </div>

  <!-- BG TITLE -->
  <div class="mm-content__title">menu</div>

  <!-- LOGO -->
  <div class="mm-content__logo" [class.visible]="mmActive" [class.invisible]="!mmActive">
    <picture>
      <source type="image/webp" srcset="assets/images/pitwall_logo.webp">
      <source type="image/jpeg" srcset="assets/images/pitwall_logo.png">
      <img src="assets/images/pitwall_logo.png" alt="pitwall logo" width="1280" height="370"/>
    </picture>
  </div>

</div>
