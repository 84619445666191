import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LsService {

  private static checkJSON(itemToCheck: string): boolean {
    let item = {};
    try {
      item = JSON.parse(itemToCheck);
    } catch (e) {
      return false;
    }
    return typeof item === 'object' && item !== null;
  }

  getItem(varName): any {
    return LsService.checkJSON(localStorage.getItem(environment.storagePrefix + varName)) ?
      JSON.parse(localStorage.getItem(environment.storagePrefix + varName)) :
      localStorage.getItem(environment.storagePrefix + varName);
  }

  getItems(searchString: string): any[] {
    const tempResults = [];
    for (const key in localStorage) {
      if (localStorage.hasOwnProperty(key)) {
        if (key.indexOf(searchString) > -1) {
          if (LsService.checkJSON(localStorage[key])) {
            tempResults.push(JSON.parse(localStorage[key]));
          } else {
            tempResults.push(localStorage[key]);
          }
        }
      }
    }
    return tempResults;
  }

  setItem(varName: string, varValue: any): void {
    if (typeof varValue === 'object') {
      localStorage.setItem(environment.storagePrefix + varName, JSON.stringify(varValue));
    } else {
      localStorage.setItem(environment.storagePrefix + varName, varValue);
    }
    this.updateVar(varName, varValue);
  }

  removeItem(varName): void {
    localStorage.removeItem(environment.storagePrefix + varName);
    this.updateVar(varName, null);
  }

  updateVar(varName: string, varValue: any): void {
    if (typeof this[varName] !== 'undefined') {
      this[varName] = varValue;
    }
  }

  setInit(): void {
    // SET DEFAULTS
    this.setLsVars();
  }

  private setLsVars() {
    // SET OR UPDATE THE GLOBAL LS VARIABLES
  }

}
