export class LtsOwnedModel {
  name: string;
  pw: string;

  constructor(objIn: any) {
    this.name = objIn.Name || '';
    this.pw = objIn.Password || '';
  }
}

// make sure the attributes match WidgetIdType !!!!
export class LtsOverlayOptionsModel {
  lb: LtsOverlayLbOptionsModel;
  msg: LtsOverlayMsgOptionsModel;
  tm: LtsOverlayTmOptionsModel;

  constructor(objIn: any) {
    this.lb = new LtsOverlayLbOptionsModel(objIn.lb || {});
    this.msg = new LtsOverlayMsgOptionsModel(objIn.msg || {});
    this.tm = new LtsOverlayTmOptionsModel(objIn.tm || {});
  }

}

export class LtsOverlayLbOptionsModel {
  activeCam: boolean;
  autoScroll: boolean;
  brand: boolean;
  category: 'car-cat' | 'driver-cat';
  class: boolean;
  gap: 'leader' | 'ahead';
  nationality: boolean;
  scrollTo: number;

  constructor(objIn: any) {
    this.activeCam = objIn.hasOwnProperty('activeCam') ? objIn.activeCam : false;
    this.autoScroll = objIn.hasOwnProperty('autoScroll') ? objIn.autoScroll : false;
    this.brand = objIn.hasOwnProperty('brand') ? objIn.brand : false;
    this.category = objIn.category || 'car-cat';
    this.class = objIn.hasOwnProperty('class') ? objIn.class : false;
    this.gap = objIn.gap || 'leader';
    this.nationality = objIn.hasOwnProperty('nationality') ? objIn.nationality : true;
    this.scrollTo = objIn.scrollTo && !Number.isNaN(Number(objIn.scrollTo)) && !this.autoScroll ? Number(objIn.scrollTo) : 1;
  }

}

export class LtsOverlayMsgOptionsModel {
  animType: 'roll' | 'fade-in';
  bannerBg: boolean;
  bestLaps: boolean;
  flags: boolean;
  fontSize: number;
  incidents: boolean;
  penalties: boolean;
  raceControl: boolean;

  constructor(objIn: any) {
    this.animType = objIn.animType || 'roll';
    this.bannerBg = objIn.hasOwnProperty('bannerBg') ? objIn.bannerBg : true;
    this.bestLaps = objIn.hasOwnProperty('bestLaps') ? objIn.bestLaps : true;
    this.flags = objIn.hasOwnProperty('flags') ? objIn.flags : true;
    this.fontSize = objIn.fontSize && !Number.isNaN(Number(objIn.fontSize)) ? Number(objIn.fontSize) : 34;
    this.incidents = objIn.hasOwnProperty('incidents') ? objIn.incidents : true;
    this.penalties = objIn.hasOwnProperty('penalties') ? objIn.penalties : true;
    this.raceControl = objIn.hasOwnProperty('raceControl') ? objIn.raceControl : true;
  }

}

export class LtsOverlayTmOptionsModel {
  background: boolean;
  weather: boolean;
  weatherPos: 'top' | 'bottom';

  constructor(objIn: any) {
    this.background = objIn.hasOwnProperty('background') ? objIn.background : true;
    this.weather = objIn.hasOwnProperty('weather') ? objIn.weather : false;
    this.weatherPos = objIn.weatherPos || 'top';
  }

}
