import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SsService {

  private static checkJSON(itemToCheck: string): boolean {
    let item = {};
    try {
      item = JSON.parse(itemToCheck);
    } catch (e) {
      return false;
    }
    return typeof item === 'object' && item !== null;
  }

  getItem(varName: string): any {
    return SsService.checkJSON(sessionStorage.getItem(environment.storagePrefix + varName)) ?
      JSON.parse(sessionStorage.getItem(environment.storagePrefix + varName)) :
      sessionStorage.getItem(environment.storagePrefix + varName);
  }

  setItem(varName: string, varValue: any): void {
    if (typeof varValue === 'object') {
      sessionStorage.setItem(environment.storagePrefix + varName, JSON.stringify(varValue));
    } else {
      sessionStorage.setItem(environment.storagePrefix + varName, varValue);
    }
    this.updateVar(varName, varValue);
  }

  removeItem(varName): void {
    sessionStorage.removeItem(environment.storagePrefix + varName);
    this.updateVar(varName, null);
  }

  updateVar(varName: string, varValue: any): void {
    if (typeof this[varName] !== 'undefined') {
      this[varName] = varValue;
    }
  }

  setInit(): void {
    // SET DEFAULTS
    this.setSsVars();
  }

  private setSsVars() {
    // SET OR UPDATE THE GLOBAL SESSION VARIABLES
  }

}
