import {ErrorService} from './error.service';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, ReplaySubject} from 'rxjs';
import {environment} from '../../../environments/environment';
import {catchError} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {SbService} from './sb.service';
import {LoadingService} from './loading.service';
import {FuncsService} from './funcs.service';
import {LtsOverlayOptionsModel, LtsOwnedModel} from '../_models/lts.model';
import {WidgetIdType} from '../../components/timing/_models/widget.model';
import {DbService} from './db.service';

@Injectable({
  providedIn: 'root'
})
export class UserLtsService {

  private ltsOwned: LtsOwnedModel;
  private ltsOwned$ = new ReplaySubject<LtsOwnedModel>(1);
  private ltsAccessList$ = new ReplaySubject<string[]>(1);

  private isListeningToDb = false;
  private ltsOverlayOptions$ = new BehaviorSubject<LtsOverlayOptionsModel>(null);

  constructor(
    private errorService: ErrorService,
    private dbService: DbService,
    private httpClient: HttpClient,
    private loadingService: LoadingService,
    private sbService: SbService,
  ) {
  }

  // ********************************************************************************************************
  // LOAD DATA
  // ********************************************************************************************************

  callGetUserLts(): void {
    this.getUserLtsHttp()
      .subscribe({
          next: (res) => {
            // so far, only 1 server per user
            this.newLtsOwned(new LtsOwnedModel(res?.length ? res[0] : {}));
            this.listenToLtsOverlayOptions();
          },
          error: (err) => {
            // always broadcast, also on error, cuz screens are waiting for it
            this.newLtsOwned(new LtsOwnedModel({}));
            this.sbService.message('Something went wrong checking your server details: ' + err.Message);
          }
        }
      );
  }

  private getUserLtsHttp(): Observable<any> {
    return this.httpClient.get(environment.urlPitBs + '/streamers/owned')
      .pipe(catchError(this.errorService.handleError));
  }

  listenToLtsOverlayOptions(usr: string = ''): void {
    if (this.ltsOwned?.name || usr) {
      const doc = this.ltsOwned?.name || usr;
      if (!this.isListeningToDb) {
        this.isListeningToDb = true;
        this.dbService.listenToDoc(environment.db.overlayOptions, doc)
          .subscribe({
              next: (opts) => {
                this.newOverlayOptions(new LtsOverlayOptionsModel(opts || {}));
              },
              error: () => {
                // do stuff ?
              }
            }
          );
      }
    }
  }

  callGetUserLtsAl(name: string): void {
    const loadMsg = 'loading access list';
    this.loadingService.start(loadMsg);
    this.getUserLtsAlHttp(name)
      .subscribe({
          next: (res) => {
            this.newLtsAccessList(res?.Emails ? res.Emails.sort((a, b) => a < b ? -1 : 1) : []);
            this.loadingService.stop(loadMsg);
          },
          error: (err) => {
            // always broadcast, also on error, cuz screens are waiting for it
            this.newLtsAccessList([]);
            this.sbService.message('Something went wrong loading your server access list: ' + err.Message);
            this.loadingService.stop(loadMsg);
          }
        }
      );
  }

  private getUserLtsAlHttp(name: string): Observable<any> {
    return this.httpClient.get(environment.urlPitBs + '/streamers/accesslist?Name=' + name)
      .pipe(catchError(this.errorService.handleError));
  }

  resetLtsOwned(): void {
    this.newOverlayOptions(null);
    this.newLtsOwned(new LtsOwnedModel({}));
    this.newLtsAccessList([]);
  }

  // ********************************************************************************************************
  // CHANGE DATA
  // ********************************************************************************************************


  callCreateLtsAl(server: string, email: string): void {
    const loadMsg = 'adding user to access list';
    this.loadingService.start(loadMsg);
    this.createLtsAlHttp(server, email)
      .subscribe({
          next: () => {
            this.sbService.message('user added');
            this.callGetUserLtsAl(server);
            this.loadingService.stop(loadMsg);
          },
          error: (err) => {
            this.sbService.message('Something went wrong adding the user: ' + err.Message);
            this.loadingService.stop(loadMsg);
          }
        }
      );
  }

  private createLtsAlHttp(server: string, email: string): Observable<any> {
    return this.httpClient.put(environment.urlPitBs + '/streamers/accesslist?Name=' + server + '&email=' + email, {})
      .pipe(catchError(this.errorService.handleError));
  }

  callDeleteLtsAl(server: string, email: string): void {
    const loadMsg = 'deleting user from access list';
    this.loadingService.start(loadMsg);
    this.deleteLtsAlHttp(server, email)
      .subscribe({
          next: () => {
            this.sbService.message('user deleted');
            this.callGetUserLtsAl(server);
            this.loadingService.stop(loadMsg);
          },
          error: (err) => {
            this.sbService.message('Something went wrong deleting the user: ' + err.Message);
            this.loadingService.stop(loadMsg);
          }
        }
      );
  }

  private deleteLtsAlHttp(server: string, email: string): Observable<any> {
    return this.httpClient.delete(environment.urlPitBs + '/streamers/accesslist?Name=' + server + '&email=' + email)
      .pipe(catchError(this.errorService.handleError));
  }

  callSaveLtsOverlayOptions(widget: WidgetIdType = null): void {
    this.saveLtsOverlayOptionsToDb(widget);
  }

  private saveLtsOverlayOptionsToDb(widget: WidgetIdType): void {
    if (this.ltsOwned.name) {
      const forDb = FuncsService.copy(this.getOverlayOptions());
      if (widget) {
        // selective update, only keep the values for the specified widget
        for (const key in forDb) {
          if (key !== widget) {
            delete forDb[key];
          }
        }
      }
      this.dbService.set(
        environment.db.overlayOptions,
        this.ltsOwned.name,
        forDb,
      )
        .then(
          () => {
            // NO NEED TO DO ANYTHING
          },
        )
        .catch(
          (err) => {
            console.log(err);
          }
        );
    }
  }

  // ********************************************************************************************************
  // BROADCAST DATA
  // ********************************************************************************************************

  private newLtsOwned(lts: LtsOwnedModel): void {
    this.ltsOwned = lts;
    this.ltsOwned$.next(lts);
  }

  onNewLtsOwned(): Observable<LtsOwnedModel> {
    return this.ltsOwned$.asObservable();
  }

  getLtsOwned(): LtsOwnedModel {
    return this.ltsOwned;
  }

  private newLtsAccessList(al: string[]): void {
    this.ltsAccessList$.next(al);
  }

  onNewLtsAccessList(): Observable<string[]> {
    return this.ltsAccessList$.asObservable();
  }

  private newOverlayOptions(opts: LtsOverlayOptionsModel): void {
    this.ltsOverlayOptions$.next(opts);
  }

  onNewOverlayOptions(): Observable<LtsOverlayOptionsModel> {
    return this.ltsOverlayOptions$.asObservable();
  }

  getOverlayOptions(): LtsOverlayOptionsModel {
    return this.ltsOverlayOptions$.getValue();
  }

}
