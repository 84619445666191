import {APP_INITIALIZER, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {ContactComponent} from './components/contact/contact.component';
import {EmailActionsComponent} from './components/email-actions/email-actions.component';
import {environment} from '../environments/environment';
import {HintModule} from './components/hint/hint.module';
import {PwaInstallService} from './components/hint/_services/pwa-install.service';
import {ServiceWorkerModule} from '@angular/service-worker';
import {SharedModule} from './shared/shared.module';
import {HomeComponent} from './components/home/home.component';
import {BlogComponent} from './components/blog/blog.component';
import {AngularFireModule} from '@angular/fire/compat';
import {AngularFirestoreModule} from '@angular/fire/compat/firestore';
import {AngularFireStorageModule} from '@angular/fire/compat/storage';
import {AngularFireAnalyticsModule, ScreenTrackingService} from '@angular/fire/compat/analytics';

const pwaInstall = (pwaService: PwaInstallService) => () => pwaService.initPwaPrompt();

@NgModule({
  declarations: [
    AppComponent,
    BlogComponent,
    ContactComponent,
    EmailActionsComponent,
    HomeComponent,
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    HintModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    SharedModule,
  ],
  providers: [
    ScreenTrackingService,
    {provide: APP_INITIALIZER, useFactory: pwaInstall, deps: [PwaInstallService], multi: true},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
