import * as moment from 'moment';
import {ErrorService} from './error.service';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {UserDbModel, UserUiModel} from '../_models/user.model';
import {environment} from '../../../environments/environment';
import {catchError} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {FuncsService} from './funcs.service';
import {DbService} from './db.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private errorService: ErrorService,
    private dbService: DbService,
    private httpClient: HttpClient,
  ) {
  }

  // ********************************************************************************************************
  // LOAD DATA
  // ********************************************************************************************************

  // ********************************************************************************************************
  // CHANGE DATA
  // ********************************************************************************************************

  // CREATE
  createUser(): Observable<any> {
    return this.httpClient.post(
      environment.urlPitBs + '/user',
      {})
      .pipe(catchError(this.errorService.handleError));
  }

  // UPDATE
  updateUser(uid: string, user: UserUiModel): Promise<any> {
    const tmpUser = new UserDbModel(uid, user);
    return this.dbService.set(
      environment.db.users,
      uid,
      {
        Settings: FuncsService.copy(tmpUser.Settings),
        Ts: moment().toISOString(),
        Uid: tmpUser.Uid,
      }
    );
  }

  // DELETE
  deleteUser(): Observable<any> {
    return this.httpClient.delete(
      environment.urlPitBs + '/user'
    )
      .pipe(catchError(this.errorService.handleError));
  }

  // ********************************************************************************************************
  // BROADCAST DATA
  // ********************************************************************************************************

}
