import {Injectable, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {environment} from '../../../environments/environment';
import {AngularFireAnalytics} from '@angular/fire/compat/analytics';

@Injectable({
  providedIn: 'root'
})
export class GaService implements OnDestroy {

  onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private fbGa: AngularFireAnalytics,
  ) {
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  setUser(uid: string): void {
    if (this.fbGa && environment.production) {
      this.fbGa.setUserId(uid);
    }
  }

  sendEvent(name: string, params: any = null): void {
    if (this.fbGa && environment.production) {
      this.fbGa.logEvent(name, params);
    }
  }

  sendException(params: any): void {
    if (this.fbGa && environment.production) {
      this.fbGa.logEvent('exception', params);
    }
  }

}
