import {Component, Input, OnInit} from '@angular/core';
import {Platform} from '@angular/cdk/platform';

declare type BgType =
  'barcelona_pitlane' | 'barcelona_pitout' | 'barcelona_race' |
  'how_it_works' |
  'nurburg_fog' | 'nurburg_pit' | 'nurburg_pitout' |
  'paulricard' | 'paulricard_pit' | 'paulricard_pitlane' | 'paulricard_sunset' | 'paulricard_track_to_pit' |
  'silverstone' | 'silverstone_pit' |
  'zolder_windy';

@Component({
  selector: 'app-bg',
  templateUrl: './bg.component.html',
  styleUrls: ['./bg.component.scss']
})
export class BgComponent implements OnInit {

  imgExt = '.webp';

  readonly bgList = {
    barcelona_pitlane : {url: '/assets/images/bgs/barcelona_pitlane', alt: 'pitwall.live at barcelona'},
    barcelona_pitout : {url: '/assets/images/bgs/barcelona_pitout', alt: 'pitwall.live at barcelona'},
    barcelona_race : {url: '/assets/images/bgs/barcelona_race', alt: 'pitwall.live at barcelona'},
    how_it_works : {url: '/assets/images/bgs/how_it_works', alt: 'ACC how it works diagram'},
    nurburg_fog : {url: '/assets/images/bgs/nurburg_fog', alt: 'pitwall.live at nurburg'},
    nurburg_pit : {url: '/assets/images/bgs/nurburg_pit', alt: 'pitwall.live at nurburg'},
    nurburg_pitout : {url: '/assets/images/bgs/nurburg_pitout', alt: 'pitwall.live at nurburg'},
    paulricard : {url: '/assets/images/bgs/paulricard', alt: 'pitwall.live at paul ricard'},
    paulricard_pit : {url: '/assets/images/bgs/paulricard_pit', alt: 'pitwall.live at paul ricard'},
    paulricard_pitlane : {url: '/assets/images/bgs/paulricard_pitlane', alt: 'pitwall.live at paul ricard'},
    paulricard_sunset : {url: '/assets/images/bgs/paulricard_sunset', alt: 'pitwall.live at paul ricard'},
    paulricard_track_to_pit : {url: '/assets/images/bgs/paulricard_track_to_pit', alt: 'pitwall.live at paul ricard'},
    silverstone : {url: '/assets/images/bgs/silverstone', alt: 'pitwall.live at silverstone'},
    silverstone_pit : {url: '/assets/images/bgs/silverstone_pit', alt: 'pitwall.live at silverstone'},
    zolder_windy : {url: '/assets/images/bgs/zolder_windy', alt: 'pitwall.live at zolder'},
  };

  // LEFT: ALIGN BG IMG LEFT INSTEAD OF DEFAULT RIGHT
  // PORTRAIT: SWITCHES BACKGROUND-SIZE FROM COVER TO AUTO (SO PORTRAIT IMGS ARE NOT STRETCHED OVER FULL WIDTH OF SCREEN)
  @Input() bgX = 100;
  @Input() bgY = 50;
  @Input() bgPortrait = false;
  @Input() bgName: BgType;
  @Input() bgAnimate = true;

  bg: any;

  constructor(
    private platform: Platform,
  ) {
    if (platform.IOS) {
      // YES, IT'S TRUE, APPLE STILL DOES NOT SUPPORT WEBP IMAGES
      this.imgExt = '.jpg';
    }
  }

  ngOnInit(): void {
    this.bg = this.bgList[this.bgName];
  }

}
