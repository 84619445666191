<div class="bg">
  <div class="bg__img-wrapper"
       [title]="bg.alt"
       [class.with-anim]="bgAnimate"
       [style.background-position-x.%]="bgX"
       [style.background-position-y.%]="bgY"
       [class.portrait]="bgPortrait"
       [style.background-image]="'url(' + bg.url + imgExt + ')'">
  </div>
</div>
