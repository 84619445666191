import {Component, OnDestroy} from '@angular/core';
import {CookiesService} from '../_services/cookies.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss']
})
export class CookiesComponent implements OnDestroy {

  hasAccepted = true;
  onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private cookiesService: CookiesService,
  ) {
    this.getAccepted();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private getAccepted(): void {
    this.cookiesService.onHasAccepted()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (res) => {
          this.hasAccepted = res;
        }
      );
  }

  onAcceptCookies(): void {
    this.cookiesService.acceptCookies();
  }

}
