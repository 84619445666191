import {Injectable} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {ActiveRouteModel} from '../_models/active-route.model';

@Injectable({
  providedIn: 'root'
})
export class ActiveRouteService {

  private activeRoute: ActiveRouteModel;
  private activeRoute$ = new ReplaySubject<ActiveRouteModel>(1);

  // ********************************************************************************************************
  // BROADCAST DATA
  // ********************************************************************************************************

  newActiveRoute(url: string, queryParams: any, params: any) {
    this.activeRoute = new ActiveRouteModel(url, queryParams, params);
    this.activeRoute$.next(this.activeRoute);
  }

  onNewActiveRoute(): Observable<ActiveRouteModel> {
    return this.activeRoute$.asObservable();
  }

  getActiveRoute(): ActiveRouteModel {
    return this.activeRoute;
  }

}
