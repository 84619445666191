export declare type EmbedModeType = '' | 'none' | 'overlay' | 'iframe';
export declare type EmbedModeParentType = '' | 'sro' | 'fia';

export enum EmbedModeEnum {
  none = 'none',
  overlay = 'overlay',
  iframe = 'iframe',
}

export class EmbedModeModel {
  parent: EmbedModeParentType;
  pwd: string;
  type: EmbedModeType;
  usr: string;
  widget: string;

  constructor() {
    this.parent = '';
    this.pwd = '';
    this.type = '';
    this.usr = '';
    this.widget = '';
  }

}
