import {Injectable} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {ActiveRouteService} from './active-route.service';
import {EmbedModeEnum, EmbedModeModel, EmbedModeParentType} from '../_models/embed-mode.model';
import {UserLtsService} from './user-lts.service';

@Injectable({
  providedIn: 'root'
})
export class EmbedModeService {

  private embedMode = new EmbedModeModel();
  private embedMode$ = new ReplaySubject<EmbedModeModel>(1);

  private static getIframeParent(): EmbedModeParentType {
    const ref = document.referrer || '';
    // important: make sure these match the whitelist in firebase.json!!!
    if (ref.includes('sro-esports')) {
      return 'sro';
    } else if (ref.includes('fiamotorsportgames')) {
      return 'fia';
    }
    return '';
  }

  constructor(
    private activeRouteService: ActiveRouteService,
    private userLtsService: UserLtsService,
  ) {
    this.getActiveRoute();
  }

  private getActiveRoute(): void {
    this.activeRouteService.onNewActiveRoute()
      .subscribe(
        (route) => {
          // only do this if embedMode is not yet set, else would fire on every navigate
          if (!this.embedMode.type) {
            if (route?.queryParams && route.queryParams.hasOwnProperty('widget')) {
              // overlay mode
              this.embedMode.pwd = route.queryParams.pwd;
              this.embedMode.type = EmbedModeEnum.overlay;
              this.embedMode.usr = route.queryParams.usr;
              this.embedMode.widget = route.queryParams.widget;
              this.userLtsService.listenToLtsOverlayOptions(this.embedMode.usr);
            } else if (window.location !== window.parent.location) {
              // iframe mode
              this.embedMode.type = EmbedModeEnum.iframe;
              this.embedMode.parent = EmbedModeService.getIframeParent();
            } else {
              // none
              this.embedMode.type = EmbedModeEnum.none;
            }
            this.newEmbedMode();
          }
        }
      );
  }

  // ********************************************************************************************************
  // LOAD DATA
  // ********************************************************************************************************

  // ********************************************************************************************************
  // BROADCAST DATA
  // ********************************************************************************************************

  private newEmbedMode() {
    console.log('embed mode set: ' + this.embedMode.type);
    this.embedMode$.next(this.embedMode);
  }

  onNewEmbedMode(): Observable<EmbedModeModel> {
    return this.embedMode$.asObservable();
  }

  getEmbedMode(): EmbedModeModel {
    return this.embedMode;
  }

}
