export const environment = {
  appVersion: require('../../package.json').version + '-dev',
  backVersion: '2.0.0',
  db: {
    carSelection: 'car-selection',
    contact: 'contact',
    dashboards: 'dashboards',
    ltsRequests: 'ltsrequests',
    overlayOptions: 'overlay-options',
    pitmond: 'pitmond',
    products: 'products',
    streamers: 'streamers',
    streams: 'streams',
    tracks: 'tracks',
    usage: 'usagestats',
    users: 'users',
  },
  firebase: {
    apiKey: 'AIzaSyAMAYv3ZnFR1sOXBiw6ho3yyqAsft1H4GQ',
    appId: '1:946073731226:web:d3bc21a97ab8839c5e5f23',
    authDomain: 'pitwall.live',
    databaseURL: 'https://pitwall-996e6.firebaseio.com',
    measurementId: 'G-4GCFMQHXQF',
    messagingSenderId: '946073731226',
    projectId: 'pitwall-996e6',
    storageBucket: 'pitwall-996e6.appspot.com',
  },
  production: false,
  storagePrefix: 'pwLitePro-dev-',
  stripePublishable: 'pk_live_51OlS3LFWhuZazfGcwVHIVQCNCQvTHSn78qSnwtJt3eW33NZGXGyYmJ4vejjXdd5rmjUkb6oI2W1uMipDsfHfX00B00JW2Ho1DC',
  urlPitBs: 'https://ovhfra1.pit-it.net:8191',
  // urlPitBs:'https://back.pit-it.net:8190',
  // urlPitBs:'http://localhost:8190',
  // urlPitBsPay: 'https://back.pit-it.net:8190/pay3',
  urlPitBsPay: 'https://ovhfra1.pit-it.net:8191/pay3',
  // urlPitBsPay: 'http://localhost:8190/pay3',
  urlWs: 'back.pit-it.net',
};
