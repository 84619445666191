<div class="contact">

  <div class="contact__form txt">

    <div>
      <h1>contact</h1>
    </div>

    <div class="contact__form__intro">
      <ng-container *ngIf="contactSubject === 'partner'; else GenericIntro">
        <span class="primary">It seems you're interested in partnering up? Great!!</span><br>
        Let's chat on our discord or fill out the form below and we'll get back to you via email
        so we can set-up a phone-call or video chat.
        <br>
      </ng-container>
      <ng-template #GenericIntro>
        The quickest way to get in touch is to join our <b>Discord</b> server, where you can talk to us directly or ask
        one of the online pitwall.live users.
      </ng-template>
      <a aria-label="link to Discord" href="https://discord.gg/trBnZke" rel="noopener" target="_blank">
        <svg viewBox="0 0 24 24">
          <use [attr.xlink:href]="'assets/icons/soc/dscrd.svg#svg1'"></use>
          <text font-size="4px" text-anchor="middle" x="12" y="24">join us</text>
        </svg>
      </a>
      <br>
      If you prefer, you can also mail us at <a href="mailto:info@pitwall.live">info@pitwall.live</a> or fill out the
      form below.
    </div>
    <div>
      <form #contactForm="ngForm" (ngSubmit)="onSend()" autocomplete="off">
        <mat-form-field>
          <input [(ngModel)]="contactName"
                 autocapitalize="off"
                 matInput
                 minlength="1"
                 name="contactname"
                 placeholder="name"
                 required>
        </mat-form-field>
        <mat-form-field>
          <input [(ngModel)]="contactEmail"
                 autocapitalize="off"
                 matInput
                 name="contactemail"
                 placeholder="email"
                 required>
        </mat-form-field>
        <mat-form-field>
          <input [(ngModel)]="contactPhoneNr"
                 autocapitalize="off"
                 matInput
                 name="contactphonenr"
                 placeholder="phone">
        </mat-form-field>
        <mat-form-field>
          <input [(ngModel)]="contactCompany"
                 autocapitalize="off"
                 matInput
                 name="contactcompany"
                 placeholder="company">
        </mat-form-field>
        <mat-form-field floatLabel="always">
          <mat-label>subject</mat-label>
          <mat-select [(ngModel)]="contactSubject"
                      name="contactsubject"
                      required>
            <mat-option *ngFor="let curSubject of contactSubjects"
                        [value]="curSubject">
              {{curSubject}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <textarea [(ngModel)]="contactQuestion"
                    autocapitalize="off"
                    matInput
                    name="contactquestion"
                    placeholder="what can we do for you?"
                    required
                    rows="8">
          </textarea>
        </mat-form-field>
        <div class="flex end">
          <button [class.disabled]="!contactForm.form.valid"
                  [disabled]="!contactForm.form.valid"
                  class="accent">
            send
          </button>
        </div>
      </form>
    </div>
  </div>

  <app-bg [bgAnimate]="false" [bgName]="'nurburg_fog'"></app-bg>

</div>

<app-footer></app-footer>
