import {ActivatedRoute, Router} from '@angular/router';
import {Component, NgZone, OnDestroy} from '@angular/core';
import {SbService} from '../../shared/_services/sb.service';
import {Subject} from 'rxjs';
import {AngularFireAuth} from '@angular/fire/compat/auth';

@Component({
  selector: 'app-email-actions',
  templateUrl: './email-actions.component.html',
  styleUrls: ['./email-actions.component.scss']
})
export class EmailActionsComponent implements OnDestroy {

  readonly ACTION_MODE_RESETPASSWORD = 'resetPassword';
  readonly ACTION_MODE_VERIFYEMAIL = 'verifyEmail';

  actionMode: string;
  newPw = '';
  newPwActionCode = '';

  // DESTROY SUBJECT
  onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private afAuth: AngularFireAuth,
    private ngZone: NgZone,
    private route: ActivatedRoute,
    private router: Router,
    private sbService: SbService,
  ) {
    this.route.queryParamMap.subscribe((params) => {
      this.actionMode = params.get('mode');
      const actionCode = params.get('oobCode');
      switch (this.actionMode) {
        case this.ACTION_MODE_RESETPASSWORD:
          this.handleResetPassword(actionCode);
          break;
        case this.ACTION_MODE_VERIFYEMAIL:
          this.handleVerifyEmail(actionCode);
          break;
        default:
          // INVALID MODE, NO IDEA WHAT TO DO
          console.log('invalid email-action mode, should not happen!!');
      }
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private handleResetPassword(actionCode: string) {
    // VERIFY THE PASSWORD RESET CODE IS VALID
    this.afAuth.verifyPasswordResetCode(actionCode).then(() => {
      this.newPwActionCode = actionCode;
      // NOW LET USER INPUT NEW PASSWORD
    }).catch((error) => {
      this.sbService.message((error.message));
    });
  }

  saveNewPassword(): void {
    if (!this.newPw) {
      this.sbService.message('Please enter a password');
      return;
    }
    this.afAuth.confirmPasswordReset(this.newPwActionCode, this.newPw)
      .then(() => {
        this.sbService.message('Password updated, please sign in to your account using your new password');
        this.router.navigate(['/account']);
      }).catch((error) => {
      this.sbService.message(error.message);
    });
  }

  private handleVerifyEmail(actionCode: string) {
    // TRY TO APPLY THE EMAIL VERIFICATION CODE
    this.afAuth.applyActionCode(actionCode).then(() => {
      // ALL GOOD, LET USER CLICK
    }).catch((error) => {
      this.sbService.message(error.message);
    });
  }

  onReload(path: string): void {
    location.assign(location.origin + path);
  }

}
