import {Injectable} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MmService {

  private mmState$ = new ReplaySubject<string>(1);

  // ********************************************************************************************************
  // BROADCAST DATA
  // ********************************************************************************************************

  newMmState(mmState: string) {
    this.mmState$.next(mmState);
  }

  onNewMmState(): Observable<string> {
    return this.mmState$.asObservable();
  }

}
