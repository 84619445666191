import {Component, OnDestroy} from '@angular/core';
import {Platform} from '@angular/cdk/platform';
import {Subject} from 'rxjs';
import {PwaInstallService} from '../_services/pwa-install.service';

@Component({
  selector: 'app-pwa-install',
  templateUrl: './pwa-install.component.html',
  styleUrls: ['./pwa-install.component.scss']
})
export class PwaInstallComponent implements OnDestroy {

  // DESTROY SUBJECT
  onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private pwaInstallService: PwaInstallService,
    public platform: Platform,
  ) {
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  doInstall(): void {
    this.pwaInstallService.doInstall();
  }

  ignoreInstall(): void {
    this.pwaInstallService.ignoreInstall();
  }

}
