<div class="home">

  <!-- TOP IMG -->
  <div class="home__top flex col end">
    <div class="home__top__slogan with-padding txt-right">
      live-timing<br>anywhere<br>anytime
    </div>
    <app-bg [bgName]="'silverstone'" [bgX]="0"></app-bg>
  </div>

  <!-- BOTTOM IMGS -->
  <div class="home__bottom flex wrap">

    <!-- WHAT IS IT -->
    <div class="home__bottom__block what-is-it with-padding flex col center">

      <div>
        Pitwall.live is the <h4>strategy tool</h4> for race-engineers, teams, drivers and fans<br>
        It brings professional, high-end data visualisations directly to your browser
      </div>
      <div class="flex">
        <svg viewBox="0 0 24 24">
          <use [attr.xlink:href]="'assets/icons/arrow_right.svg#svg1'"></use>
        </svg>
        Ease of use, no installation required
      </div>
      <div class="flex">
        <svg viewBox="0 0 24 24">
          <use [attr.xlink:href]="'assets/icons/arrow_right.svg#svg1'"></use>
        </svg>
        Runs as a web app on any device
      </div>
      <div class="flex">
        <svg viewBox="0 0 24 24">
          <use [attr.xlink:href]="'assets/icons/arrow_right.svg#svg1'"></use>
        </svg>
        Free live timing dashboard for everyone
      </div>
      <div class="flex">
        <svg viewBox="0 0 24 24">
          <use [attr.xlink:href]="'assets/icons/arrow_right.svg#svg1'"></use>
        </svg>
        <div>
          Pro features make race strategy easy
        </div>
      </div>
      <div>
        <button class="accent2" routerLink="/features">more features</button>
      </div>
      <!-- absolute bg image -->
      <div>
        <svg viewBox="0 0 512 512" class="any-device">
          <use [attr.xlink:href]="'assets/icons/any_device.svg#svg1'"></use>
        </svg>
      </div>

    </div>

    <!-- STAY UPDATED -->
    <div class="home__bottom__block stay-updated with-padding flex col end">
      <div class="side-title">
        stay updated
      </div>
      <div class="dscrd">
        <a href="https://discord.gg/trBnZke" target="_blank" rel="noopener" aria-label="link to Discord">
          <svg viewBox="0 0 24 24">
            <use [attr.xlink:href]="'assets/icons/soc/dscrd.svg#svg1'"></use>
            <text x="12" y="24" text-anchor="middle" font-size="4px">join us</text>
          </svg>
        </a>
      </div>
      <app-bg [bgName]="'silverstone_pit'" [bgX]="0" [bgAnimate]="false"></app-bg>
    </div>

    <!-- OUR PRODUCTS -->
    <div class="home__bottom__block our-products double">
      <div class="bg-accent with-padding flex col center">
        <div class="txt-center">
          <div class="txt-accent">
            We believe that live-timing for everyone benefits the entire racing community
          </div>
          That's why we have a flavour for every taste, including a free version packed with features<br>
          <a routerLink="/products">Discover our products</a>
        </div>
      </div>
      <div>
        <app-bg [bgName]="'paulricard'" [bgX]="0" [bgAnimate]="false"></app-bg>
      </div>
    </div>

    <!-- HOW IT WORKS -->
    <div class="home__bottom__block how-it-works with-padding flex col end">
      <!-- Pitwall.live is widely used <br>in both sim-racing and real-life racing<br> -->
      <div class="goto flex end" routerLink="/how-it-works">
        <div>find out how it works</div>
        <div class="arrow flex col center">
          <svg viewBox="0 0 24 24">
            <use [attr.xlink:href]="'assets/icons/arrow_right.svg#svg1'"></use>
          </svg>
        </div>
      </div>
      <app-bg [bgName]="'how_it_works'" [bgX]="50" [bgAnimate]="false"></app-bg>
    </div>
  </div>

</div>

<app-footer></app-footer>
