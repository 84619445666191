<div class="pwa-install">
  <ng-container *ngIf="platform.IOS; else NoIos">
    Install pitwall.live as an app : tap
    <svg viewBox="0 0 24 24">
      <use [attr.xlink:href]="'assets/icons/soc/apple_share.svg#svg1'"></use>
    </svg>
    and then "Add to homescreen"
    <div class="pwa-install__buttons">
      <button class="primary" (click)="ignoreInstall()">got it</button>
    </div>
  </ng-container>
  <ng-template #NoIos>
    Install pitwall.live as {{platform.ANDROID ? 'an app' : 'a desktop app'}}?
    <div class="pwa-install__buttons flex center column-gap-10 pad-big-top">
      <button class="accent" (click)="ignoreInstall()">no</button>
      <button class="primary" (click)="doInstall()">do it</button>
    </div>
  </ng-template>
</div>
