<div *ngIf="product?.name"
     class="price flex col center">

  <div class="price__title flex center {{productCssClass}}">
    <div>
      <h1>{{product.label}}</h1>
      <svg *ngIf="userHasProduct" viewBox="0 0 24 24">
        <use xlink:href="assets/icons/check.svg#svg1"></use>
      </svg>
    </div>
  </div>

  <div class="price__flavour">

    €{{(forcePrice || product.price) / 100}}/{{product.period}}
    <sup class="price__flavour__info-icon"
         matTooltip="this does not include access to private or exclusive servers such as servers for real-life racing"
         matTooltipClass="bg-color">
      <mat-icon fontIcon="info_outline"></mat-icon>
    </sup>

  </div>
</div>
