import {environment} from '../../../environments/environment';

export const NO_ACTIVE_SERVER = 'no-server';
export const COMMUNITY_SERVER_TYPE = 'community-std';

export declare type SiteType = '' | 'site' | 'facebook' | 'discord' | 'insta';

export class ServerModel {
  category: string;
  comm: string;
  description: string;
  directLink: string;
  id: string;
  name: string;
  port: number;
  isPrivate: boolean;
  site: string;
  siteType: SiteType;
  url: string;

  constructor(objIn: any) {
    this.category = objIn.Category || '';
    this.comm = objIn.Comm || '';
    this.description = objIn.Description || '';
    this.directLink = objIn.DirectLink || '';
    this.name = objIn.Name || '';
    this.id = objIn.Id || '';
    this.port = objIn.Port || 0;
    this.isPrivate = objIn.hasOwnProperty('IsPrivate') ? objIn.IsPrivate : false;
    this.site = objIn.Site || '';
    this.siteType = ServerModel.setSiteType(objIn.Site);
    this.url = objIn.Server || environment.urlWs;
  }

  private static setSiteType(url: string): SiteType {
    if (!url) {
      return '';
    } else if (url.includes('facebook')) {
      return 'facebook';
    } else if (url.includes('instagram')) {
      return 'insta';
    } else if (url.includes('discord')) {
      return 'discord';
    } else {
      return 'site';
    }
  }

}

export class ServerPitmondModel {
  isLive: boolean;
  name: string;
  db: ServerModel;
  trackName: string;
  trackSvg: string;
  trackViewbox: string;
  category: string;

  constructor(server: ServerModel, pwbIn: any, pwrIn: any) {
    this.isLive = pwrIn.Status?.UpLinkConnected === 1;
    this.name = server.name;
    this.db = server;
    this.trackName = pwbIn.Status?.Track || '';
    this.trackSvg = pwbIn.Status?.TrackData?.Svg || '';
    this.trackViewbox = pwbIn.Status?.TrackData?.ViewBox || '';
    this.category = server.category;
  }

}
