<div *ngIf="!hasAccepted" class="cookies">
  <div class="cookies__text">
    by using our site you agree to our
    <a routerLink="/legal/terms-conditions">terms & conditions</a>
    and
    <a routerLink="/legal/privacy">privacy policy</a>
  </div>
  <div class="cookies__buttons">
    <button class="bg" (click)="onAcceptCookies()">got it</button>
  </div>
</div>
