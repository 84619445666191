<div class="cart" [class.active]="!cart?.isPaid && cart?.products?.length && activeRoute !== '/payment'">
  <div class="cart__txt">
    <div class="cart__txt__content" [class.active]="cartTxt">{{cartTxt}}</div>
  </div>
  <div class="cart__placeholder" routerLink="/payment" (mouseenter)="setTxt(null, true)">
    <app-hexagon [hexWithFill]="true" [hexSize]="64">
      <div>
        <svg viewBox="0 0 24 24">
          <use xlink:href="assets/icons/cart.svg#svg1"></use>
        </svg>
      </div>
    </app-hexagon>
  </div>
</div>
