import {ActiveRouteService} from '../_services/active-route.service';
import {CartModel} from '../_models/cart.model';
import {CartService} from '../_services/cart.service';
import {Component, OnDestroy} from '@angular/core';
import {Subject, timer} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnDestroy {

  activeRoute = '';
  cart: CartModel;
  cartTxt = '';

  stopTimer$: Subject<void> = new Subject<void>();
  onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private activeRouteService: ActiveRouteService,
    private cartService: CartService,
  ) {
    this.onNewActiveRoute();
    this.onNewCart();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private onNewCart(): void {
    this.cartService.onNewCart()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (cart) => {
          this.setTxt(cart);
          this.cart = cart;
        }
      );
  }

  private onNewActiveRoute(): void {
    this.activeRouteService.onNewActiveRoute()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (route) => {
          this.activeRoute = route.url;
        }
      );
  }

  setTxt(cart: CartModel, isHover = false): void {
    if (isHover) {
      this.cartTxt = this.cart.products.length === 1 ? '1 product in your cart' : this.cart.products.length + ' products in your cart';
      this.setTimer();
      return;
    }
    if (!cart?.products.length || cart.fromSs) {
      this.cartTxt = '';
      return;
    }
    if (this.cart?.products.length) {
      // TODO: SUPPORT MULTIPLE PRODUCTS
      if (cart.products[0].name === this.cart?.products[0].name) {
        this.cartTxt = 'already in your cart';
      } else {
        this.cartTxt = 'removed "' + this.cart.products[0].label + '" and added "' + cart.products[0].label + '" to your cart';
      }
    } else {
      this.cartTxt = 'added "' + cart.products[0].label + '" to your cart';
    }
    this.setTimer();
  }

  private setTimer(): void {
    if (this.cartTxt) {
      this.stopTimer$.next();
      timer(4000)
        .pipe(takeUntil(this.onDestroy$))
        .pipe(takeUntil(this.stopTimer$))
        .subscribe(() => this.cartTxt = '');
    }
  }

}
