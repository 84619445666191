import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-hexagon',
  templateUrl: './hexagon.component.html',
  styleUrls: ['./hexagon.component.scss']
})
export class HexagonComponent {

  @Input() hexClass = 'primary';
  @Input() hexSize = 44;
  @Input() hexStrokeWidth = 20;
  @Input() hexWithFill = false;

  constructor() { }

}
